import './styles.scss';

import { Form } from 'antd';
import clsx from 'clsx';
import B2becSpinner from 'components/B2becSpinner';
import {
  B2becStepItem,
  B2becStepItemLabel,
  B2becSteps,
} from 'components/B2becSteps';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useDocumentTitle from 'hooks/useDocumentTitle';
import usePermission from 'hooks/usePermission';
import { ASYNC_STATUS, SERVICE_ENTRY, SERVICE_STEP } from 'libs/constants';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { MODULE_SERVICE_RIGHTS } from 'libs/constants/modulerights';
import { isValidArray } from 'libs/utils/array';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ProductService } from 'services';
import { getDeliveryAddresses } from 'store/selectors/customerSelector';
import { getSelectedMachineData } from 'store/selectors/serviceCaseSelector';
import {
  resetSelectedMachineInfo,
  setSelectedMachineInfo,
} from 'store/slices/serviceCaseSlice';

import useAsync from '../../hooks/useAsync';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import AddressAndDateSelectStep from './StepComponents/SelectAddressAndDate';
import MachineSelectStep from './StepComponents/SelectMachine';
import ServiceSelectStep from './StepComponents/SelectService';
import ServiceOverviewStep from './StepComponents/ServiceOverview';
import { onValidateFields } from './validators';

const RequestServicePage = (props) => {
  const { title, breadcrumbs, pageId } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { isMobile } = useDeviceDetect();
  const { setPageInfoData } = useAdobeAnalysis();

  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);

  const [currentTab, setCurrentTab] = useState(1);

  const selectedMachineData = useSelector(getSelectedMachineData);
  const deliveryAddresses = useSelector(getDeliveryAddresses);
  const isMountedRef = useRef(null);

  const {
    verifiedPermissions: [canCreateRepairService, canCreateMaintenanceService],
  } = usePermission([
    MODULE_SERVICE_RIGHTS.CREATE_REPAIR_SERVICE_CASE,
    MODULE_SERVICE_RIGHTS.CREATE_MAINTENANCE_SERVICE_CASE,
  ]);

  const defaultDeliveryAddress = useMemo(() => {
    if (isValidArray(deliveryAddresses)) {
      return {
        ...deliveryAddresses[0],
        isAddNew: false,
      };
    }
    return { isAddNew: false };
  }, [deliveryAddresses]);

  const formItemLayout = {
    labelAlign: 'left',
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  useEffect(() => {
    if (selectedMachineData) {
      const { serviceEntry, id } = selectedMachineData;
      const pageName = `request service:${serviceEntry}${
        id && `:${id}`
      }:step_${currentTab}`;

      setPageInfoData({
        pageId,
        pageType: PAGE_TYPES.SERVICE_CASES,
        pageName,
      });
    }
  }, [setPageInfoData, selectedMachineData, pageId, currentTab]);

  const handleChangeTab = useCallback(
    (tabIndex) => {
      onValidateFields(
        form,
        tabIndex,
        selectedMachineData?.serviceEntry,
        setCurrentTab
      );
    },
    [form, setCurrentTab, selectedMachineData.serviceEntry]
  );

  const serviceSteps = useMemo(
    () => [
      {
        label: 'service.selectMachine.tabTitle',
        step: SERVICE_STEP.SELECT_MACHINE,
        content: (
          <MachineSelectStep
            currentTab={currentTab}
            handleChangeTab={handleChangeTab}
          />
        ),
      },
      {
        label: 'service.selectService.tabTitle',
        step: SERVICE_STEP.SELECT_SERVICE,
        content: (
          <ServiceSelectStep
            form={form}
            currentTab={currentTab}
            handleChangeTab={handleChangeTab}
          />
        ),
      },
      {
        label: 'service.addressAndDate.tabTitle',
        step: SERVICE_STEP.SELECT_ADDRESS_n_DATE,
        content: (
          <AddressAndDateSelectStep
            form={form}
            currentTab={currentTab}
            handleChangeTab={handleChangeTab}
            serviceEntry={selectedMachineData?.serviceEntry}
          />
        ),
      },
      {
        label: 'service.serviceOverview.tabTitle',
        step: SERVICE_STEP.SERVICE_OVERVIEW,
        content: (
          <ServiceOverviewStep
            form={form}
            currentTab={currentTab}
            handleChangeTab={handleChangeTab}
          />
        ),
      },
    ],
    [currentTab, handleChangeTab, form, selectedMachineData.serviceEntry]
  );

  const renderServiceProcessSteps = useCallback(() => {
    switch (selectedMachineData?.serviceEntry) {
      case SERVICE_ENTRY.MAINTENANCE: {
        return serviceSteps.filter(
          (serviceStep) => serviceStep?.step !== SERVICE_STEP.SELECT_SERVICE
        );
      }
      case SERVICE_ENTRY.MACHINE: {
        return serviceSteps.filter(
          (serviceStep) => serviceStep?.step !== SERVICE_STEP.SELECT_MACHINE
        );
      }
      default: {
        return serviceSteps;
      }
    }
  }, [selectedMachineData.serviceEntry, serviceSteps]);

  const getMachineBasicInfoApi = (materialNumber) => {
    return ProductService.getBasicProductInfo(materialNumber);
  };

  const { execute, status: getBasicProductInfoStatus } = useAsync(
    getMachineBasicInfoApi,
    false
  );

  // get machine basic information
  useEffect(
    () => {
      if (
        selectedMachineData?.serviceEntry === SERVICE_ENTRY.MACHINE &&
        selectedMachineData?.id
      ) {
        isMountedRef.current = true;

        execute(selectedMachineData?.id).then(({ response }) => {
          if (isMountedRef.current && response) {
            dispatch(
              setSelectedMachineInfo({
                name: response?.data?.materialName,
                image: response?.data?.pictureUrl,
              })
            );
          }
        });
      }
      return () => {
        isMountedRef.current = false;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, selectedMachineData.id, selectedMachineData.serviceEntry]
  );

  // reset the machine information when changing service entry
  useEffect(() => {
    const { isChangedService, serviceEntry } = selectedMachineData;

    // for case restart page and service entry is not stored
    if (!isChangedService && serviceEntry === null) {
      // set service type based on user's right, Repair type by default if available
      const defaultServiceEntry = canCreateRepairService
        ? SERVICE_ENTRY.REPAIR
        : SERVICE_ENTRY.MAINTENANCE;
      // if both service types are unavailable, then user will be redirected to 401 page
      dispatch(
        setSelectedMachineInfo({
          serviceEntry: defaultServiceEntry,
        })
      );
    }

    if (
      selectedMachineData?.isChangedService &&
      selectedMachineData?.serviceEntry !== null
    ) {
      // clear the form
      form.resetFields();
      // trigger action to reset data
      dispatch(resetSelectedMachineInfo());
      // redirect back to first tab
      handleChangeTab(1);
    }
  }, [
    form,
    dispatch,
    handleChangeTab,
    selectedMachineData,
    canCreateRepairService,
  ]);

  const renderServiceTitle = useCallback(() => {
    if (selectedMachineData?.serviceEntry) {
      switch (selectedMachineData?.serviceEntry) {
        case SERVICE_ENTRY.MAINTENANCE:
          return <>{t('service.headerMenu.requestMaintenance')}</>;
        case SERVICE_ENTRY.MACHINE:
          return <>{t('createServiceRequest')}</>;
        default:
          return <>{t('service.headerMenu.requestRepair')}</>;
      }
    }
    return <>{t('service.headerMenu.requestRepair')}</>;
  }, [selectedMachineData.serviceEntry, t]);

  const validateMessages = {
    required: t('myProfile.form.validateMessages.required'),
    string: {
      max: t('errors.maxInputExceeded'),
    },
  };

  if (!canCreateMaintenanceService && !canCreateRepairService) {
    return <Redirect to="/403" />;
  }

  return getBasicProductInfoStatus === ASYNC_STATUS.PENDING ? (
    <B2becSpinner
      isLoading={getBasicProductInfoStatus === ASYNC_STATUS.PENDING}
    />
  ) : (
    <Form
      key={defaultDeliveryAddress?.partnerNumber}
      form={form}
      {...formItemLayout}
      layout="vertical"
      validateMessages={validateMessages}
      initialValues={{
        addressType: 1,
        deliveryAddress: defaultDeliveryAddress,
      }}
    >
      <div
        className={clsx('service-request', {
          'full-width-page-component__wrapper': isMobile,
        })}
      >
        <div className="service-request__wrapper">
          <h1 className="service-request__title service-request__layout--shrink">
            {renderServiceTitle()}
          </h1>
          <B2becSteps
            defaultActiveKey="1"
            activeKey={`${currentTab}`}
            onChange={(key) => handleChangeTab(key)}
          >
            {renderServiceProcessSteps().map((serviceStep, index) => (
              <B2becStepItem
                tab={
                  <B2becStepItemLabel
                    index={+index + 1}
                    label={t(serviceStep?.label)}
                  />
                }
                // eslint-disable-next-line react/no-array-index-key
                key={+index + 1}
              >
                {serviceStep?.content}
              </B2becStepItem>
            ))}
          </B2becSteps>
        </div>
      </div>
    </Form>
  );
};

RequestServicePage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default RequestServicePage;
