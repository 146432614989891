import {
  CONFIG_DATE_FORMAT,
  DEFAULT_CONFIG_DATE_FORMAT,
} from 'libs/constants/configs';
import moment from 'moment';

export const isValidDateFormat = (format) => {
  return CONFIG_DATE_FORMAT.find(({ value }) => value === format) !== -1;
};

const utils = (() => {
  let PRIVATE_FORMAT = DEFAULT_CONFIG_DATE_FORMAT;
  return () => {
    const FORMAT_DATE_UTC = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
    const FORMAT_DATE_DEFAULT = PRIVATE_FORMAT;
    const FORMAT_DATE_WITH_TIME = `${FORMAT_DATE_DEFAULT} HH:mm`;
    const FORMAT_TIME = 'h:mm a';
    const FORMAT_TIME_DETAIL = 'HH:mm:ss A';
    const FORMAT_DATE_WITH_DAYS_OF_WEEK = `dd ${FORMAT_DATE_DEFAULT}`;

    const formatType = {
      utc: FORMAT_DATE_UTC,
      default: FORMAT_DATE_DEFAULT,
      dateTime: FORMAT_DATE_WITH_TIME,
      time: FORMAT_TIME,
      timeDetail: FORMAT_TIME_DETAIL,
      dateWithDaysOfWeek: FORMAT_DATE_WITH_DAYS_OF_WEEK,
    };

    return {
      formatType,
      setDateFormat: (format) => {
        if (isValidDateFormat(format)) {
          PRIVATE_FORMAT = format;
        }
      },
    };
  };
})();

export const CURRENT_DATE = new Date();

export const setDateFormat = (format) => utils().setDateFormat(format);

export const getFormatType = () => utils().formatType;

export const formatDate = (dateParam, outputFormat, isStrictMode = false) => {
  if (!dateParam) return undefined;
  const formatType = getFormatType();
  const date = moment(dateParam, formatType.FORMAT_DATE_UTC, isStrictMode);

  if (date.isValid()) {
    return date.format(outputFormat || formatType.default);
  }

  return undefined;
};

export const convertDateToMoment = (dateParam, format) => {
  if (!dateParam) return null;
  const result = moment(dateParam, format);
  if (result.isValid()) {
    return result;
  }
  return undefined;
};

export const convertDateToMomentISOString = (date, dateFormat) => {
  if (date) {
    const momentDate = moment(date, dateFormat).toDate().toISOString();
    return momentDate;
  }

  return '';
};

export const convertDateToISOString = (dateParam) =>
  dateParam?.utc()?.toISOString();

export const createMomentFromDateAndTime = (date, time) => {
  if (date) {
    return moment({
      year: date.year(),
      month: date.month(),
      date: date.date(),
      hour: time?.hour(),
      minute: time?.minute(),
    });
  }
  return null;
};
