import clsx from 'clsx';
// import { useLocation } from 'react-router-dom';
import B2becTranslation from 'components/B2becTranslation';
import Footer from 'components/Layout/components/Footer';
import Header from 'components/PublicHeader/Header';
import ScrollToTop from 'components/ScrollToTop';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useDocumentTitle from 'hooks/useDocumentTitle';
import i18n from 'i18next';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { parseHtml } from 'libs/utils/common';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './DataProtection.module.scss';

const DataProtection = ({ title, pageId }) => {
  useDocumentTitle(title);
  // const location = useLocation();
  const language = localStorage.getItem('lng');
  const { t } = useTranslation();

  useEffect(() => {
    if (window.OneTrust) {
      window.OneTrust.initializeCookiePolicyHtml(true);
    }
  }, [language]);

  useAdobeAnalysis(null, {
    pageName: 'data protection policy',
    pageId,
    pageType: PAGE_TYPES.POLICY,
  });

  const renderSections = () => {
    const sections = [];
    let i = 1;

    while (i18n.exists(`dataProtectionPolicy.section${i}`)) {
      sections.push(
        <section className="data-policy-section" key={`section-${i}`}>
          {parseHtml(
            t(`dataProtectionPolicy.section${i}`).replace(/>\s+</g, '><')
          )}
        </section>
      );

      i += 1;
    }

    return sections;
  };

  return (
    <>
      <Header />
      <ScrollToTop />
      <div className={styles.wrapper}>
        <div className={styles.containerWidth}>
          <B2becTranslation
            value="dataProtectionPolicy.mainContent"
            isComponent
            htmlTags={[
              ...renderSections(),
              <div key="ot-sdk-cookie-policy" id="ot-sdk-cookie-policy" />,
              <button
                type="button"
                key="ot-sdk-btn"
                id="ot-sdk-btn"
                className={clsx('ot-sdk-show-settings', styles.settingButton)}
              >
                {t('dataProtectionPolicy.cookieSettings')}
              </button>,
            ]}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

DataProtection.propTypes = {
  title: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
};

export default DataProtection;
