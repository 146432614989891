import './styles.scss';

import { Checkbox, Col, Divider, Row } from 'antd';
import clsx from 'clsx';
import B2BecLink from 'components/B2BLink';
import FormatPrice from 'components/FormatPrice';
import { useCheckoutContext } from 'contexts/checkout-context';
import usePermission from 'hooks/usePermission';
import { MODULE_CART_CHECKOUT_RIGHTS } from 'libs/constants/modulerights';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectCartData,
  selectIsDisplayingDisableBProgramCheckbox,
} from 'store/selectors/cartSelector';
import { selectIsCurrentTimeInSAPDowntime } from 'store/selectors/configSelector';

import { PlaceOrderButton } from '../../components/ButtonsWrapper';

function CostSummary(props) {
  const { t } = useTranslation();
  const { darkBackground, showTitle, showTotal, form, submitFormHandler } =
    props;

  const {
    currencyUnit,
    cartSubTotal,
    additionalCost,
    cartTotal,
    voucherValue,
    cartTotalVAT,
    checkoutSettings,
    vat,
  } = useSelector(selectCartData);

  const { shouldDisableCheckout, setDisableCheckout } = useCheckoutContext();

  const shouldDisplayCheckbox = useSelector(
    selectIsDisplayingDisableBProgramCheckbox
  );

  const isCurrentTimeInSAPDowntime = useSelector(
    selectIsCurrentTimeInSAPDowntime
  );

  const shippingCosts = form.getFieldValue('shippingOptions');
  const { hasPermission: hasViewIndividualPricePermission } = usePermission(
    MODULE_CART_CHECKOUT_RIGHTS.VIEW_INDIVIDUAL_PRICE
  );

  const renderDisableBProgramCheckbox = useCallback(() => {
    if (!shouldDisplayCheckbox) return null;
    return (
      <>
        <Divider className="cost-summary__divider" />
        <Checkbox
          className="kaercher-checkbox"
          onChange={(e) => setDisableCheckout(!e?.target.checked)}
        >
          {t('checkout.step3.upperSection.bProgramCheckboxText')}
        </Checkbox>
      </>
    );
  }, [t, shouldDisplayCheckbox, setDisableCheckout]);

  const renderSubtitles = (classNames, title, price, voucherCode) => {
    const columnSpans = {
      left: { xs: 14, sm: 14, md: 14, lg: 14, xl: 14 },
      right: { xs: 10, sm: 10, md: 10, lg: 10, xl: 10 },
    };

    return (
      <Row className={classNames} hidden={!hasViewIndividualPricePermission}>
        <Col className="cost-summary__subtitle" {...columnSpans.left}>
          <p className="mb-0">{title}</p>
          {voucherCode && <p className="mb-0">{voucherCode}</p>}
        </Col>
        <Col
          className={clsx('cost-summary__price', {
            'cost-summary__voucher-price': !!voucherCode,
          })}
          {...columnSpans.right}
        >
          <FormatPrice
            price={price}
            currencyUnit={currencyUnit}
            wrappedBy="p"
            className="mb-0"
          />
        </Col>
      </Row>
    );
  };

  return (
    <div className="cost-summary__wrapper">
      <div
        className={clsx('cost-summary__inner-container', {
          'cost-summary__upper-section__inner-container__padding': showTitle,
          'cost-summary__lower-section__inner-container__padding': !showTitle,
          'cost-summary__inner-container--dark-background': darkBackground,
        })}
      >
        {showTitle ? (
          <h3 className="cost-summary__title mb-0">
            {t('checkout.step3.upperSection.rightPart.title')}
          </h3>
        ) : null}
        {renderSubtitles(
          {
            'cost-summary__upper-section__subtotal': showTitle,
            'cost-summary__lower-section__subtotal': !showTitle,
            'cost-summary__upper-section__normal-row': true,
          },
          t('checkout.step3.upperSection.rightPart.subtotal'),
          cartSubTotal
        )}
        {renderSubtitles(
          {
            'cost-summary__upper-section__additional-cost': showTitle,
            'cost-summary__lower-section__additional-cost': !showTitle,
          },
          t('checkout.step3.upperSection.rightPart.additionalCost'),
          additionalCost
        )}
        {renderSubtitles(
          {
            'cost-summary__upper-section__shipping-cost': showTitle,
            'cost-summary__lower-section__shipping-cost': !showTitle,
            'cost-summary__upper-section__normal-row': true,
          },
          t('checkout.step3.upperSection.rightPart.shippingCosts'),
          shippingCosts
        )}
        {showTotal
          ? renderSubtitles(
              {
                'cost-summary__upper-section__total': showTitle,
                'cost-summary__lower-section__total': !showTitle,
              },
              t('checkout.step3.upperSection.rightPart.total'),
              cartTotal
            )
          : null}

        {showTotal &&
          renderSubtitles(
            {
              'cost-summary__upper-section__voucher-cost': showTitle,
              'cost-summary__lower-section__voucher-cost': !showTitle,
            },
            t('voucher.fields.voucherCode'),
            voucherValue?.totalDiscount,
            voucherValue?.code
          )}

        {checkoutSettings?.includingVAT && showTotal
          ? renderSubtitles(
              {
                'cost-summary__upper-section__vat': showTitle,
                'cost-summary__lower-section__vat': !showTitle,
              },
              t('vat'),
              vat
            )
          : null}

        {checkoutSettings?.includingVAT && showTotal
          ? renderSubtitles(
              {
                'cost-summary__upper-section__total': showTitle,
                'cost-summary__lower-section__total': !showTitle,
              },
              t('totalWithVat'),
              cartTotalVAT
            )
          : null}

        {showTitle ? (
          <>
            <Divider className="cost-summary__divider" />
            <p
              className="cost-summary__general-terms-and-conditions"
              style={{ paddingBottom: shouldDisplayCheckbox ? '16px' : 0 }}
            >
              {t(
                'checkout.step3.upperSection.rightPart.generalTermsAndConditions.description'
              )}{' '}
              <B2BecLink
                className="cost-summary__general-terms-and-conditions__link"
                to="/general-terms-and-conditions"
                target="_blank"
              >
                {t(
                  'checkout.step3.upperSection.rightPart.generalTermsAndConditions.link'
                )}
              </B2BecLink>
              &nbsp;
              <B2BecLink
                className="cost-summary__general-terms-and-conditions__link"
                to="/data-protection-policy"
                target="_blank"
              >
                {t('footer.dataProtection')}
              </B2BecLink>
            </p>
            {renderDisableBProgramCheckbox()}
          </>
        ) : null}
      </div>
      {showTitle ? (
        <div className="cost-summary__button fw-container">
          <PlaceOrderButton
            submitFormHandler={submitFormHandler}
            className="cost-summary__wrapper__btn"
            disabled={shouldDisableCheckout || isCurrentTimeInSAPDowntime}
          />
        </div>
      ) : null}
    </div>
  );
}

CostSummary.propTypes = {
  darkBackground: PropTypes.bool,
  showTitle: PropTypes.bool,
  showTotal: PropTypes.bool,
  form: PropTypes.shape({
    getFieldValue: PropTypes.func,
  }).isRequired,
  submitFormHandler: PropTypes.func,
};

CostSummary.defaultProps = {
  darkBackground: false,
  showTitle: false,
  showTotal: false,
  submitFormHandler: () => {},
};

export default CostSummary;
