import { Button, Col, Row } from 'antd';
import B2becButton from 'components/B2becButton';
import B2BecLink from 'components/B2BLink';
import {
  BurgerMenuIcon,
  CartIcon,
  ChevronRightIcon,
  CloseIcon,
  SearchIcon,
  UserIcon,
} from 'components/Icons';
import { useSearchContext } from 'contexts/search';
import useDeviceDetect from 'hooks/useDeviceDetect';
import usePunchout from 'hooks/usePunchout';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectMiniCartItems,
  selectTotalQuantity,
} from 'store/selectors/cartSelector';
import {
  getCustomerAddressPartnerNumber,
  getIsLoading,
} from 'store/selectors/customerSelector';
import {
  selectCustomerNumberForTechnicalUser,
  selectHasSessionBasedShoppingCart,
  selectIsProcurementTechnicalUserCxml,
} from 'store/selectors/userSelector';
import { authenticationActions } from 'store/slices/authenticationSlice';
import { setSearchTerm } from 'store/slices/searchSlice';

import PermissionWrapper from '../../../HOCs/permissionWrapper';
import {
  MODULE_PROFILE_RIGHTS,
  MODULE_SHOP_NAVIGATION_RIGHTS,
} from '../../../libs/constants/modulerights';
import { selectLogo } from '../../../store/selectors/configSelector';
import BaseBadge from '../../BaseBadge';
import HeaderMenu from '../HeaderMenu';

function UserInfoSection({ isFetching }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Todo: Refactor later,
  // Todo: partnerNumber is for Customer
  // Todo: customerNumber is for Technical
  const partnerNumber = useSelector(getCustomerAddressPartnerNumber);
  const customerNumber = useSelector(selectCustomerNumberForTechnicalUser);

  const handleLogout = () => {
    dispatch(authenticationActions.signOut());
  };

  return (
    <>
      <UserIcon className="header__user-section__button__icon" />
      <div className="header__user-section__user-menu">
        <div className="header__user-section__logout-wrapper">
          <h4>{t('miniProfile.myId')}</h4>
          <span className="header__user-section__user-name">
            {isFetching ? (
              <SkeletonTheme color="#f8f8f8" highlightColor="#fff">
                <Skeleton />
              </SkeletonTheme>
            ) : (
              partnerNumber || customerNumber || 'N/A'
            )}
          </span>

          <p className="link-to-profile">
            <PermissionWrapper permission={MODULE_PROFILE_RIGHTS.VIEW_PROFILE}>
              <B2BecLink to="/profile">
                <ChevronRightIcon />
                <span>{t('miniProfile.myProfile')}</span>
              </B2BecLink>
            </PermissionWrapper>
          </p>

          <PermissionWrapper
            permission={MODULE_PROFILE_RIGHTS.SEE_LOGOUT_BUTTON}
          >
            <Button
              className="kaercher-button"
              type="primary"
              onClick={handleLogout}
            >
              {t('logout')}
            </Button>
          </PermissionWrapper>
        </div>
      </div>
    </>
  );
}

UserInfoSection.propTypes = {
  isFetching: PropTypes.bool,
};

UserInfoSection.defaultProps = {
  isFetching: false,
};

function CartInfoSection({ totalQuantity }) {
  return (
    <B2BecLink aria-label="Cart" to="/cart" className="header-nav__main-button">
      <BaseBadge
        count={totalQuantity}
        isMiniCart
        className="header__user-section__cart-badge"
      />
      <CartIcon className="header__user-section__button__icon" />
    </B2BecLink>
  );
}

CartInfoSection.propTypes = {
  totalQuantity: PropTypes.number,
};

CartInfoSection.defaultProps = {
  totalQuantity: 0,
};

const HeaderLogoSection = (props) => {
  const { onShowDrawerMobile, onHideDrawerMobile, inBurgerMenu } = props;
  const dispatch = useDispatch();
  const { isIpadLandscape } = useDeviceDetect();
  const { isSearchFocused, setIsSearchFocused } = useSearchContext();

  const isFetching = useSelector(getIsLoading);
  const miniCartItems = useSelector(selectMiniCartItems);
  const totalQuantity = useSelector(selectTotalQuantity);
  const logo = useSelector(selectLogo);
  const hasSessionBasedShoppingCart = useSelector(
    selectHasSessionBasedShoppingCart
  );
  const isCxmlProcurementUser = useSelector(
    selectIsProcurementTechnicalUserCxml
  );
  const { onReturnToProcurement } = usePunchout() || {};
  const { t } = useTranslation();

  const handleClickOnLogo = useCallback(() => {
    dispatch(setSearchTerm(''));
  }, [dispatch]);

  const handleSearchBar = () => {
    if (inBurgerMenu) {
      onHideDrawerMobile();
    }
    setIsSearchFocused(true);
  };

  const handleOnBlurSearch = useCallback(() => {
    setIsSearchFocused(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReturnToProcurementSystem = () => {
    if (
      hasSessionBasedShoppingCart &&
      typeof onReturnToProcurement === 'function'
    ) {
      onReturnToProcurement();
    }
  };

  return (
    <div className="header__bottom__container">
      <div className="header__bottom">
        <Row>
          <Col span={24} className="header__bottom__container__menu">
            <div className="header__logo">
              <B2BecLink to="/my-workspace" onClick={handleClickOnLogo}>
                <img src={logo} alt="Kaercher Logo" className="kaercher-logo" />
              </B2BecLink>
            </div>

            {!isIpadLandscape && <HeaderMenu />}

            <div className="header__bottom__container__menu__icons">
              {isCxmlProcurementUser && (
                <div className="header__return-to-purchase-system">
                  <B2becButton
                    type="button"
                    onClick={handleReturnToProcurementSystem}
                  >
                    {t('returnToPurchaseSystemButton')}
                  </B2becButton>
                </div>
              )}
              <div className="header__user-section__button element--relative menu-item--small header-submenu-container">
                <UserInfoSection isFetching={isFetching} />
              </div>

              <div className="header__user-section__button menu-item--small position__relative">
                <PermissionWrapper
                  permission={MODULE_SHOP_NAVIGATION_RIGHTS.VIEW_CART}
                >
                  <CartInfoSection
                    miniCartItems={miniCartItems}
                    totalQuantity={totalQuantity}
                  />
                </PermissionWrapper>
              </div>

              {isIpadLandscape && (
                <>
                  <div className="header__user-section__button menu-item--small">
                    {isSearchFocused ? (
                      <CloseIcon
                        className="header__user-section__button__icon"
                        onClick={handleOnBlurSearch}
                      />
                    ) : (
                      <SearchIcon
                        className="header__user-section__button__icon"
                        onClick={handleSearchBar}
                      />
                    )}
                  </div>
                  {!inBurgerMenu && (
                    <div className="header__user-section__button menu-item--small">
                      <BurgerMenuIcon
                        className="header__user-section__button__icon menu-burger-icon"
                        onClick={onShowDrawerMobile}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

HeaderLogoSection.propTypes = {
  inBurgerMenu: PropTypes.bool,
  onShowDrawerMobile: PropTypes.func,
  onHideDrawerMobile: PropTypes.func,
};

HeaderLogoSection.defaultProps = {
  inBurgerMenu: false,
  onShowDrawerMobile: () => { },
  onHideDrawerMobile: () => { },
};

export default React.memo(HeaderLogoSection);
