export const FIELDS_MAX_LENGTH = {
  name: 35,
  name2: 35,
  city: 35,
  street: 35,
  street4: 60,
  houseNumber: 10,
  PostalCode: 10,
  Telephone: 16,
  EMail: 241,
};
