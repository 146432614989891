import { Amplify } from 'aws-amplify';
import environment from 'environments/environment.dev';

const {
  aws: { cognito },
  loginUrl,
} = environment;

export const configureAmplify = () => {
  Amplify.configure({
    Auth: {
      region: cognito.region,
      userPoolId: cognito.userPoolId,
      userPoolWebClientId: cognito.clientId,
      oauth: {
        domain: cognito.oauthDomain,
        scope: ['email', 'openid', 'profile'],
        redirectSignIn: `${window.location.origin}/callback`,
        redirectSignOut: `${loginUrl}/connect/endsession`,
        responseType: 'code',
      },
    },
  });
};
