import { NotificationManager } from 'components/Notification';
import { put, takeLatest } from 'redux-saga/effects';
import { adminFapiTechnicalUser } from 'services/adminFapi';
import { getAssignedRolesByUserId } from 'store/slices/roleSlice';
import { userActions } from 'store/slices/userSlice';

import {
  getDetails,
  getDetailsFailed,
  getDetailsSuccess,
  updateDetails,
  updateDetailsFailed,
  updateDetailsSuccess,
} from '../slices/technicalSlice';

const mapTechnicalResponse = (data) => {
  const userInfo = { ...data };
  const displayName = `${userInfo.firstName} ${userInfo.lastName}`;
  userInfo.displayName = displayName;
  // TODO when role and right ready
  userInfo.role = 'Customer';
  return userInfo;
};

function* getDetailsSaga({ payload }) {
  const userId = payload;
  try {
    const { data } = yield adminFapiTechnicalUser.getUserTechnicalById(userId);
    const userData = mapTechnicalResponse(data);

    yield put(getDetailsSuccess(userData));
  } catch (error) {
    yield put(getDetailsFailed({ error }));
  }
}

function* updateDetailsSaga({ payload }) {
  try {
    const { currentUserId, ...data } = payload;
    yield adminFapiTechnicalUser.updateTechnical(data);
    yield put(updateDetailsSuccess(payload));
    yield put(getDetails(payload.userId));
    yield put(getAssignedRolesByUserId(payload.userId));
    NotificationManager.success({
      message: 'userManagement.messages.editUserSuccess',
    });

    // if technical user update their own details, refresh the user details
    if (currentUserId === payload.userId) {
      yield put(userActions.getUserDetails({ userId: payload.userId }));
    }
  } catch (error) {
    yield put(updateDetailsFailed());
  }
}

export default function* technicalSaga() {
  yield takeLatest(getDetails.type, getDetailsSaga);
  yield takeLatest(updateDetails.type, updateDetailsSaga);
}
