import B2becPagination from 'components/B2becPagination';
import PermissionWrapper from 'HOCs/permissionWrapper';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useDocumentTitle from 'hooks/useDocumentTitle';
import usePermission from 'hooks/usePermission';
import { PAGE_SIZE, SORT_ORDERS } from 'libs/constants';
import { PAGE_TYPES } from 'libs/constants/adobeAnalytics';
import { MODULE_ORDER_RIGHTS } from 'libs/constants/modulerights';
import { linkGenerator } from 'libs/utils/language';
import moment from 'moment';
import { DatePeriod } from 'pages/OrderPage/OrdersHistoryPage/DatePeriod';
import { Search } from 'pages/OrderPage/OrdersHistoryPage/Search';
import { Status } from 'pages/OrderPage/OrdersHistoryPage/Status';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectDateFormat } from 'store/selectors/configSelector';
import {
  selectIsFetchingOrdersList,
  selectOrdersList,
  selectOrderTotalCount,
} from 'store/selectors/ordersSelector';
import { ordersActions } from 'store/slices/orderSlice';
import { convertDateToMomentISOString } from 'libs/utils/formatDate'
import styles from './OrdersHistory.module.scss';
import OrdersHistoryTable from './OrdersHistoryTable';

const SORT_FIELD = {
  orderDate: 'orderDate',
};

const updateQueryParams = (params, resetPage = false) => {
  const newQueryParams = new URLSearchParams(window.location.search);
  Object.keys(params).forEach((key) => {
    if (params[key]) {
      newQueryParams.set(key, params[key]);
    } else {
      newQueryParams.delete(key);
    }
  });
  if (resetPage) {
    newQueryParams.set('page', '1');
  }
  return newQueryParams;
};

function OrdersHistoryPage(props) {
  const { title, breadcrumbs, pageId } = props;
  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);
  const { hasPermission: hasViewOrderHistoryPermission } = usePermission(
    MODULE_ORDER_RIGHTS.VIEW_ORDER_HISTORY
  );

  useAdobeAnalysis(null, {
    pageName: 'order history overview',
    pageType: PAGE_TYPES.ORDER,
    pageId,
  });

  const history = useHistory();
  const params = useMemo(
    () => new URLSearchParams(window.location.search),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.location.search]
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentPage = parseInt(params.get('page'), 10) || 1;
  const searchTerm = params.get('q') || '';
  const selectedStatus = params.get('s') || '';
  const from = params.get('from') || '';
  const to = params.get('to') || '';
  const [sortBy, orderBy] = (
    params.get('sort') ?? `${SORT_FIELD.orderDate}.${SORT_ORDERS.descend}`
  )?.split('.');

  const totalCount = useSelector(selectOrderTotalCount);
  const orderList = useSelector(selectOrdersList);
  const isFetchingOrdersList = useSelector(selectIsFetchingOrdersList);
  const dateFormat = useSelector(selectDateFormat);

  const handlePageChange = useCallback(
    (page) => {
      const newQueryParams = updateQueryParams({ page });
      history.push({
        pathname: linkGenerator('/orders'),
        search: newQueryParams.toString(),
      });
    },
    [history]
  );

  const handleSearch = useCallback(
    (value) => {
      const newQueryParams = updateQueryParams({ q: value }, true);

      history.replace({
        pathname: linkGenerator('/orders'),
        search: newQueryParams.toString(),
      });
    },
    [history]
  );

  const handleFilterByStatus = useCallback(
    (value) => {
      const newQueryParams = updateQueryParams({ s: value }, true);
      history.replace({
        pathname: linkGenerator('/orders'),
        search: newQueryParams.toString(),
      });
    },
    [history]
  );

  const toInclusiveDate = (dateString, dateFormat) => {
    const isoDate = convertDateToMomentISOString(dateString, dateFormat)
    const inclusiveDate = moment(isoDate).clone().add(1, 'days');
    const inclusiveIsoDate = convertDateToMomentISOString(inclusiveDate, dateFormat)
    return inclusiveIsoDate;
  }

  const handleDateChange = useCallback(
    (_, datesString) => {
      const newQueryParams = updateQueryParams(
        {
          from: datesString[0]
            ? convertDateToMomentISOString(datesString[0], dateFormat)
            : null,
          to: datesString[1]
            ? toInclusiveDate(datesString[1], dateFormat)
            : null,
        },
        true
      );

      history.push({
        pathname: linkGenerator('/orders'),
        search: newQueryParams.toString(),
      });
    },
    [history, dateFormat]
  );

  const handleChangeSorter = useCallback(
    (_, __, sorter) => {
      const { field, order } = sorter;

      const newQueryParams = updateQueryParams(
        {
          sort: `${field}.${order}`,
        },
        true
      );

      history.push({
        pathname: linkGenerator('/orders'),
        search: newQueryParams.toString(),
      });
    },
    [history]
  );

  const search = useMemo(() => {
    return <Search searchTerm={searchTerm} onSearch={handleSearch} />;
  }, [searchTerm, handleSearch]);

  const status = useMemo(() => {
    return (
      <Status
        selectedStatus={selectedStatus}
        onSelectStatus={handleFilterByStatus}
      />
    );
  }, [selectedStatus, handleFilterByStatus]);

  const datePeriod = useMemo(() => {
    return <DatePeriod from={from} to={to} onDateChange={handleDateChange} />;
  }, [from, to, handleDateChange]);

  const orderHistoryTable = useMemo(() => {
    return (
      <OrdersHistoryTable
        items={orderList}
        isLoading={isFetchingOrdersList}
        currentPage={currentPage}
        onChange={handleChangeSorter}
      />
    );
  }, [currentPage, isFetchingOrdersList, orderList, handleChangeSorter]);

  const pagination = useMemo(() => {
    return (
      <B2becPagination
        pageSize={PAGE_SIZE[10]}
        total={totalCount}
        onPageChange={handlePageChange}
        current={currentPage}
      />
    );
  }, [currentPage, totalCount, handlePageChange]);

  useEffect(() => {
    if (hasViewOrderHistoryPermission) {
      dispatch(
        ordersActions.getOrdersList({
          pageNumber: currentPage,
          pageSize: PAGE_SIZE[10],
          orderBy,
          sortBy,
          searchTerm,
          selectedStatus,
          dateFrom: from,
          dateTo: to,
        })
      );
    }
  }, [
    dispatch,
    currentPage,
    sortBy,
    orderBy,
    searchTerm,
    selectedStatus,
    from,
    to,
    hasViewOrderHistoryPermission,
  ]);

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{t('orders.history.title')}</h1>
      <PermissionWrapper permission={MODULE_ORDER_RIGHTS.VIEW_ORDER_HISTORY}>
        <div className={styles.header}>
          {search}
          <div className={styles.right}>
            {datePeriod}
            {status}
          </div>
        </div>
        {orderHistoryTable}
        {pagination}
      </PermissionWrapper>
    </div>
  );
}

OrdersHistoryPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default OrdersHistoryPage;
