import './styles.scss';

import { Layout } from 'antd';
import clsx from 'clsx';
import { useSearchContext } from 'contexts/search';
import { useCheckLocation } from 'hooks';
import useDeviceDetect from 'hooks/useDeviceDetect';
import { OFFSET_TOP, STICKY_ZINDEX } from 'libs/constants/header';
import { renderHeaderSearchStickyTop } from 'libs/utils/breakpointsValue';
import React, { memo, useCallback, useState } from 'react';
import Sticky from 'react-stickynode';

import PermissionWrapper from '../../HOCs/permissionWrapper';
import { MODULE_SHOP_NAVIGATION_RIGHTS } from '../../libs/constants/modulerights';
import Search from '../Search';
import HeaderLogoSection from './components';
import HeaderMenuMobile from './HeaderMenu/Mobile';

const { Header: HeaderLayout } = Layout;

function Header() {
  const { isSearchFocused, setIsSearchFocused } = useSearchContext();

  const { isLargeScreen, isIpadLandscape } = useDeviceDetect();

  const [isShowDrawerMobile, setShowDrawerMobile] = useState(false);
  const { isInMyWorkspace } = useCheckLocation();

  const onCloseDrawerMobile = () => {
    setShowDrawerMobile(false);
  };

  const onShowDrawerMobile = useCallback(() => {
    setShowDrawerMobile(true);
    setIsSearchFocused(false);
  }, [setIsSearchFocused]);

  const onFocusSearch = useCallback(() => {
    setIsSearchFocused(true);
  }, [setIsSearchFocused]);

  const onBlurSearch = useCallback(() => {
    setIsSearchFocused(false);
  }, [setIsSearchFocused]);

  const searchClass = clsx(
    'header__bottom__search__wrapper',
    isInMyWorkspace && 'header__bottom__search__wrapper-homepage',
    !isLargeScreen && 'header__bottom__search__wrapper--mobile'
  );

  return (
    <HeaderLayout className="header-wrapper">
      {/* Logo & Navigation */}
      <Sticky innerZ={STICKY_ZINDEX.MENU} top={OFFSET_TOP.HEADER_MENU}>
        <HeaderLogoSection
          handleOnBlurSearch={onBlurSearch}
          onFocusSearch={onFocusSearch}
          onShowDrawerMobile={onShowDrawerMobile}
          onHideDrawerMobile={onCloseDrawerMobile}
        />
      </Sticky>
      {isShowDrawerMobile ? (
        <HeaderMenuMobile
          isShow={isShowDrawerMobile}
          onClose={onCloseDrawerMobile}
        />
      ) : null}

      {isIpadLandscape ? (
        isSearchFocused && (
          <div
            className={searchClass}
            style={{
              position: 'absolute',
              zIndex: STICKY_ZINDEX.SEARCH_IN_MOBILE,
            }}
          >
            <PermissionWrapper
              permission={MODULE_SHOP_NAVIGATION_RIGHTS.USE_SEARCH}
            >
              <Search />
            </PermissionWrapper>
          </div>
        )
      ) : (
        <Sticky
          innerZ={STICKY_ZINDEX.SEARCH}
          top={renderHeaderSearchStickyTop(isLargeScreen)}
        >
          {/* Search */}
          <PermissionWrapper
            permission={MODULE_SHOP_NAVIGATION_RIGHTS.USE_SEARCH}
          >
            <div className={searchClass}>
              {/* Hide below content when scrolling in Main page in desktop
             <div className="header__bottom__search__yellow-banner" /> */}
              {isSearchFocused && (
                <div className="header__bottom__search__background-layer--dim" />
              )}

              <Search />
            </div>
          </PermissionWrapper>
        </Sticky>
      )}
    </HeaderLayout>
  );
}

export default memo(Header);
