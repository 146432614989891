import B2BecLink from 'components/B2BLink';
import {
  ClientViewFilledIcon,
  ClientViewIcon,
  ConfigurationsIcon,
  ConfigurationsWhiteIcon,
  ContactFilledIcon,
  ContactIcon,
  DebugRequestFilledIcon,
  DebugRequestIcon,
  HeartFilledSidebarIcon,
  HeartSidebarIcon,
  MachinesFilledIcon,
  MachinesIcon,
  OrdersFilledIcon,
  OrdersIcon,
  OverviewFilledIcon,
  OverviewIcon,
  PromotionFilledIcon,
  PromotionsIcon,
  RentalFilledSidebarIcon,
  RentalSidebarIcon,
  RolesManagementIcon,
  ServiceCaseManagementIcon,
  TranslationFilledIcon,
  TranslationIcon,
  UsersFilledIcon,
  UsersIcon,
  VouchersFilledIcon,
  VouchersIcon,
} from 'components/Icons';
import usePermission from 'hooks/usePermission';
import { LINK_NAMES } from 'libs/constants/adobeAnalytics';
import { PHRASE_PROJECT_URL } from 'libs/constants/lang';
import {
  MODULE_ADMINISTRATION_RIGHTS,
  MODULE_DEBUGGING_RIGHTS,
  MODULE_MACHINE_RIGHTS,
  MODULE_ORDER_RIGHTS,
  MODULE_PROMOTION_VOUCHER_ADMINISTRATION_RIGHTS,
  MODULE_SERVICE_RIGHTS,
  MODULE_WISHLIST_RIGHTS,
} from 'libs/constants/modulerights';
import { analyzeLinkName } from 'libs/utils/adobeAnalysis';
import { linkGenerator } from 'libs/utils/language';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { selectIsRentalAvailable } from 'store/selectors/configSelector';
import { selectIsAdminView } from 'store/selectors/menuSelector';
import { trackLinkName } from 'store/slices/adobeAnalyticSlice';
import { setIsAdminView } from 'store/slices/menuSlice';
import { getSAPLog } from 'store/slices/SAPLogSlice';

import styles from './SidebarMenu.module.scss';

export function useMenu() {
  const isRentalAvailable = useSelector(selectIsRentalAvailable);
  const isAdminView = useSelector(selectIsAdminView);
  const { checkPermission } = usePermission();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const {
    verifiedPermissions: [canViewAdminArea, canReadDebug, canViewWishlist],
  } = usePermission([
    MODULE_ADMINISTRATION_RIGHTS.VIEW_ADMIN_AREA,
    MODULE_DEBUGGING_RIGHTS.READ_DEBUG,
    MODULE_WISHLIST_RIGHTS.VIEW_WISHLIST,
  ]);
  const shouldShowAdminDivider = canViewAdminArea || canReadDebug;

  // To clone an object, Object can include number, string, symbol properties
  const clone = useCallback(
    (items) => items.map((item) => (Array.isArray(item) ? clone(item) : item)),
    []
  );

  const adminMenu = useMemo(
    () =>
      [
        {
          key: 'admin-workspace',
          title: 'sidebar.admin.overview',
          NormalIcon: <OverviewIcon className="my-workspace__icons--normal" />,
          SelectedIcon: (
            <OverviewFilledIcon className="my-workspace__icons--selected" />
          ),
          path: '/admin-workspace',
        },
        {
          key: 'admin-translation',
          title: 'sidebar.admin.translationAdministration',
          NormalIcon: (
            <TranslationIcon className="my-workspace__icons--normal" />
          ),
          SelectedIcon: (
            <TranslationFilledIcon className="my-workspace__icons--selected" />
          ),
          path: PHRASE_PROJECT_URL,
          isExternal: true,
        },
        {
          key: 'voucher-management',
          title: 'sidebar.admin.voucherManagement',
          NormalIcon: <VouchersIcon className="my-workspace__icons--normal" />,
          SelectedIcon: (
            <VouchersFilledIcon className="my-workspace__icons--selected" />
          ),
          path: '/voucher-management',
          requirePermission:
            MODULE_PROMOTION_VOUCHER_ADMINISTRATION_RIGHTS.VIEW_VOUCHER,
        },
        {
          key: 'promotion-management',
          title: 'sidebar.admin.promotionManagement',
          NormalIcon: (
            <PromotionsIcon className="my-workspace__icons--normal" />
          ),
          SelectedIcon: (
            <PromotionFilledIcon className="my-workspace__icons--selected" />
          ),
          path: '/promotion-management',
          requirePermission:
            MODULE_PROMOTION_VOUCHER_ADMINISTRATION_RIGHTS.UPDATE_PROMOTION,
        },
        {
          key: 'contact-reasons',
          title: 'sidebar.admin.contactReasonManagement',
          NormalIcon: <ContactIcon className="my-workspace__icons--normal" />,
          SelectedIcon: (
            <ContactFilledIcon className="my-workspace__icons--selected" />
          ),
          path: '/contact-reasons',
          requirePermission: MODULE_ADMINISTRATION_RIGHTS.CRUD_CONTACT_REASON,
        },
        {
          key: 'user-management',
          title: 'sidebar.admin.userManagement',
          NormalIcon: <UsersIcon className="my-workspace__icons--normal" />,
          SelectedIcon: (
            <UsersFilledIcon className="my-workspace__icons--selected" />
          ),
          path: '/user-management',
        },
        {
          key: 'rental-management',
          title: 'sidebar.admin.rentalManagement',
          NormalIcon: (
            <RentalSidebarIcon className="my-workspace__icons--normal" />
          ),
          SelectedIcon: (
            <RentalFilledSidebarIcon className="my-workspace__icons--selected" />
          ),
          path: '/rental-management',
        },
        {
          key: 'configuration-management',
          title: 'sidebar.admin.configurations',
          NormalIcon: (
            <ConfigurationsIcon className="my-workspace__icons--normal" />
          ),
          SelectedIcon: (
            <ConfigurationsWhiteIcon className="my-workspace__icons--selected" />
          ),
          path: '/configuration-management',
          requirePermission: MODULE_ADMINISTRATION_RIGHTS.VIEW_CONFIGURATION,
        },
        {
          key: 'service-management',
          title: 'sidebar.admin.serviceCaseEntry',
          NormalIcon: (
            <ServiceCaseManagementIcon className="my-workspace__icons--normal " />
          ),
          SelectedIcon: (
            <ServiceCaseManagementIcon className="my-workspace__icons--selected" />
          ),
          path: '/service-management',
        },
        {
          key: 'roles-management',
          title: 'sidebar.admin.roleManagement',
          NormalIcon: (
            <RolesManagementIcon className="my-workspace__icons--normal " />
          ),
          SelectedIcon: (
            <RolesManagementIcon className="my-workspace__icons--selected" />
          ),
          path: '/roles-management',
          requirePermission: MODULE_ADMINISTRATION_RIGHTS.ROLE_RIGHT,
        },
        {
          key: 'product-management',
          title: 'sidebar.admin.productManagement',
          NormalIcon: (
            <ConfigurationsIcon className="my-workspace__icons--normal" />
          ),
          SelectedIcon: (
            <ConfigurationsWhiteIcon className="my-workspace__icons--selected" />
          ),
          path: '/product-management',
          requirePermission:
            MODULE_ADMINISTRATION_RIGHTS.VIEW_PRODUCT_PORFOLIO_MANAGEMENT,
        },
        {
          key: 'support-area',
          title: 'sidebar.admin.supportArea',
          NormalIcon: (
            <ConfigurationsIcon className="my-workspace__icons--normal" />
          ),
          SelectedIcon: (
            <ConfigurationsWhiteIcon className="my-workspace__icons--selected" />
          ),
          path: '/support-area',
          requirePermission: MODULE_ADMINISTRATION_RIGHTS.SUPPORT_AREA,
        },
        {
          type: 'divider',
        },
        {
          key: 'change-view',
          title: 'sidebar.clientView',
          NormalIcon: (
            <ClientViewIcon className="my-workspace__icons--normal" />
          ),
          SelectedIcon: (
            <ClientViewFilledIcon className="my-workspace__icons--selected" />
          ),
        },
        canReadDebug && {
          key: 'debug-request',
          title: 'sidebar.admin.debuggingRequests',
          NormalIcon: (
            <DebugRequestIcon className="my-workspace__icons--normal" />
          ),
          SelectedIcon: (
            <DebugRequestFilledIcon className="my-workspace__icons--selected" />
          ),
        },
      ].filter(Boolean),
    [canReadDebug]
  );

  const clientMenu = useMemo(
    () =>
      [
        {
          key: 'my-workspace',
          title: 'sidebar.myWorkspace',
          NormalIcon: <OverviewIcon className="my-workspace__icons--normal" />,
          SelectedIcon: (
            <OverviewFilledIcon className="my-workspace__icons--selected" />
          ),
          linkName: LINK_NAMES.SIDEBAR_NAVIGATION.MY_WORKSPACE,
          path: '/my-workspace',
        },
        {
          key: 'my-machines',
          title: 'sidebar.myMachine',
          NormalIcon: <MachinesIcon className="my-workspace__icons--normal" />,
          SelectedIcon: (
            <MachinesFilledIcon className="my-workspace__icons--selected" />
          ),
          linkName: LINK_NAMES.SIDEBAR_NAVIGATION.MY_MACHINES,
          path: '/my-machines',
          requirePermission: MODULE_MACHINE_RIGHTS.VIEW_MACHINE_LIST,
        },
        isRentalAvailable && {
          key: 'rental',
          title: 'sidebar.rental',
          NormalIcon: (
            <RentalSidebarIcon className="my-workspace__icons--normal" />
          ),
          SelectedIcon: (
            <RentalFilledSidebarIcon className="my-workspace__icons--selected" />
          ),
          linkName: LINK_NAMES.SIDEBAR_NAVIGATION.RENTAL,
          path: '/rental',
        },
        {
          key: 'orders',
          title: 'sidebar.orders',
          NormalIcon: <OrdersIcon className="my-workspace__icons--normal" />,
          SelectedIcon: (
            <OrdersFilledIcon className="my-workspace__icons--selected" />
          ),
          linkName: LINK_NAMES.SIDEBAR_NAVIGATION.ORDERS,
          path: '/orders',
          requirePermission: MODULE_ORDER_RIGHTS.VIEW_ORDER_HISTORY,
        },
        canViewWishlist && {
          key: 'wishlists',
          title: 'sidebar.wishlists',
          NormalIcon: (
            <HeartSidebarIcon
              height={16}
              width={16}
              className="my-workspace__icons--normal"
            />
          ),
          SelectedIcon: (
            <HeartFilledSidebarIcon
              height={16}
              width={16}
              className="my-workspace__icons--selected"
            />
          ),
          linkName: LINK_NAMES.SIDEBAR_NAVIGATION.WISHLISTS,
          path: '/wishlists',
        },
        {
          key: 'contact-form',
          title: 'sidebar.contact',
          NormalIcon: <ContactIcon className="my-workspace__icons--normal" />,
          SelectedIcon: (
            <ContactFilledIcon className="my-workspace__icons--selected" />
          ),
          linkName: LINK_NAMES.SIDEBAR_NAVIGATION.CONTACT,
          path: '/contact-form',
          requirePermission: MODULE_SERVICE_RIGHTS.VIEW_CONTACT_FORM,
        },
        shouldShowAdminDivider && {
          type: 'divider',
        },
        canViewAdminArea && {
          key: 'change-view',
          title: 'sidebar.adminView',
          NormalIcon: (
            <ClientViewIcon className="my-workspace__icons--normal" />
          ),
          SelectedIcon: (
            <ClientViewFilledIcon className="my-workspace__icons--selected" />
          ),
        },
        canReadDebug && {
          key: 'debug-request',
          title: 'sidebar.admin.debuggingRequests',
          NormalIcon: (
            <DebugRequestIcon className="my-workspace__icons--normal" />
          ),
          SelectedIcon: (
            <DebugRequestFilledIcon className="my-workspace__icons--selected" />
          ),
        },
      ].filter(Boolean),
    [
      canReadDebug,
      canViewAdminArea,
      canViewWishlist,
      isRentalAvailable,
      shouldShowAdminDivider,
    ]
  );

  const clonedMenu = isAdminView ? clone(adminMenu) : clone(clientMenu);

  const sidebarKeys = useMemo(() => {
    if (isAdminView) {
      return adminMenu.map(({ key }) => key);
    }
    return clientMenu.map(({ key }) => key);
  }, [adminMenu, clientMenu, isAdminView]);

  const handleMenuItemClick = useCallback(
    (e) => {
      if (e.key === 'change-view') {
        dispatch(setIsAdminView());

        if (!isAdminView) {
          history.replace(linkGenerator('/admin-workspace'));
        }
      } else if (e.key === 'debug-request') {
        dispatch(getSAPLog());
      }
    },
    [dispatch, history, isAdminView]
  );

  const clickHandler = useCallback(
    (linkName) => {
      if (linkName) {
        dispatch(trackLinkName(analyzeLinkName(linkName)));
      }
    },
    [dispatch]
  );

  // To get the selected item on the sidebar to highlight it
  const currentKey = useMemo(
    () => sidebarKeys.find((key) => pathname.includes(key)) || sidebarKeys[0],
    [pathname, sidebarKeys]
  );

  const items = useMemo(() => {
    return clonedMenu
      .filter((item) => {
        if (item?.requirePermission) {
          return checkPermission(item?.requirePermission);
        }
        return true;
      })
      .map(
        ({
          key,
          isExternal,
          NormalIcon,
          SelectedIcon,
          title,
          path,
          linkName,
          type,
        }) => {
          if (type === 'divider') {
            return { type };
          }

          const menuItemContent = (
            <div>
              <div className={styles.iconsWrapper}>
                {NormalIcon}
                {SelectedIcon}
              </div>
              <span>{t(title)}</span>
            </div>
          );

          const label = isExternal ? (
            <a href={path} target="_blank" rel="noopener noreferrer">
              {menuItemContent}
            </a>
          ) : (
            <B2BecLink
              state={{ linkName }}
              to={path}
              onClick={() => clickHandler(linkName)}
            >
              {menuItemContent}
            </B2BecLink>
          );

          return {
            key,
            label,
          };
        }
      );
  }, [checkPermission, clonedMenu, clickHandler, t]);

  return {
    items,
    currentKey,
    onMenuItemClick: handleMenuItemClick,
  };
}
