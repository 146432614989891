import { Col, Row, Tag } from 'antd';
import { B2bUserEditForm } from 'components/B2bUserForm';
import CustomButton from 'components/CustomButton';
import { DEFAULT_LANGUAGES } from 'libs/constants/lang';
import { isValidArray } from 'libs/utils/array';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { selectCountryNames } from 'store/selectors/configSelector';

import usePermission from '../../../../../../hooks/usePermission';
import { MODULE_USER_MANAGEMENT_RIGHTS } from '../../../../../../libs/constants/modulerights';
import styles from './UserInformationSection.module.scss';

function UserInformationSection({
  isLoading,
  availableLanguages,
  userId,
  onEditSuccess,
  preferredLanguageCode,
  customerNumber: sapCustomerNumber,
  isInvitation,
  assignedRoles,
  countryId,
  ...info
}) {
  const { t } = useTranslation();

  const [isEdit, setIsEdit] = useState(false);

  const { hasPermission: hasEditUserPermission } = usePermission(
    MODULE_USER_MANAGEMENT_RIGHTS.UPDATE_USER
  );

  const countryNamesConfig = useSelector(selectCountryNames);

  const isShowEditUserButton =
    isInvitation || (!isInvitation && hasEditUserPermission);
  const showEditForm = isEdit && userId;

  const countryName = useMemo(() => {
    const foundCountryName = countryNamesConfig.find(
      (country) => country.value === countryId
    );

    return foundCountryName ? foundCountryName.name : countryId;
  }, [countryId, countryNamesConfig]);

  const languageLabel = useMemo(() => {
    if (!preferredLanguageCode || preferredLanguageCode === 'N/A') {
      return 'N/A';
    }
    const matchedLang =
      isValidArray(availableLanguages) &&
      availableLanguages.find((lang) => lang?.value === preferredLanguageCode);

    if (!matchedLang?.label) {
      return preferredLanguageCode;
    }
    return matchedLang?.label;
  }, [availableLanguages, preferredLanguageCode]);

  const handleEditSuccess = () => {
    setIsEdit(false);
    onEditSuccess();
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.title}>
        {t(
          'adminWorkspace.userManagement.userDetails.userInformationSection.title'
        )}
      </div>
      {showEditForm ? (
        <B2bUserEditForm
          userId={userId}
          initialValues={{
            language: preferredLanguageCode,
            sapCustomerNumber,
            sessionBasedShoppingCart: false,
            countryId: countryName,
            assignedRoles,
            ...info,
          }}
          isInvitation={isInvitation}
          onSuccess={handleEditSuccess}
          onCancel={() => setIsEdit(false)}
        />
      ) : (
        <>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <div className={styles.subTitle}>
                {isLoading ? <Skeleton /> : info.name || 'N/A'}
              </div>
              <div>{isLoading ? <Skeleton /> : info.email || 'N/A'}</div>
              <div>{isLoading ? <Skeleton /> : info.phoneNumber || 'N/A'}</div>
            </Col>
            <Col xs={24} sm={12}>
              <div className={styles.subTitle}>
                {t(
                  'adminWorkspace.userManagement.userDetails.userInformationSection.roleAssigned'
                )}
              </div>
              <div>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  isValidArray(assignedRoles) && (
                    <div className={styles.assignedRolesWrapper}>
                      {assignedRoles.map((role) => (
                        <Tag className={styles.assignedRole} key={role?.roleId}>
                          {role?.roleName}
                        </Tag>
                      ))}
                    </div>
                  )
                )}
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div className={styles.subTitle}>{t('form.label.salesOrg')}</div>
              <div>{isLoading ? <Skeleton /> : <>{countryId}</>}</div>
            </Col>
            <Col xs={24} sm={12}>
              <div className={styles.subTitle}>
                {t(
                  'adminWorkspace.userManagement.userDetails.userInformationSection.language'
                )}
              </div>
              <div>{isLoading ? <Skeleton /> : languageLabel}</div>
            </Col>
          </Row>
          {isShowEditUserButton && (
            <CustomButton
              className={styles.editUserInfoBtn}
              disabled={isLoading}
              onClick={() => setIsEdit(true)}
            >
              {t(
                'adminWorkspace.userManagement.userDetails.userInformationSection.button'
              )}
            </CustomButton>
          )}
        </>
      )}
    </section>
  );
}

UserInformationSection.propTypes = {
  isLoading: PropTypes.bool,
  isLoadingAvailableLanguages: PropTypes.bool,
  availableLanguages: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  name: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  countryId: PropTypes.string,
  preferredLanguageCode: PropTypes.string,
  userId: PropTypes.string,
  customerNumber: PropTypes.string,
  salutation: PropTypes.string,
  onEditSuccess: PropTypes.func,
  isInvitation: PropTypes.bool,
};

UserInformationSection.defaultProps = {
  isLoading: false,
  isLoadingAvailableLanguages: false,
  availableLanguages: DEFAULT_LANGUAGES,
  name: 'N/A',
  email: 'N/A',
  phoneNumber: 'N/A',
  countryId: 'N/A',
  preferredLanguageCode: 'N/A',
  userId: undefined,
  customerNumber: undefined,
  salutation: '',
  onEditSuccess: () => {},
  isInvitation: false,
};

export default UserInformationSection;
