import {
  checkIsLanguageSupported,
  getCultureFromRegistrationLink,
} from 'libs/utils/language';
import React, { useEffect, useMemo, useState } from 'react';

const ONETRUST_CONFIG = {
  SCRIPT_ID: 'oneTrustLib',
  BUTTON_ID: 'ot-sdk-btn',
  DOMAIN_ID: 'd8f3a9b2-8a80-466f-b82f-6281c84ca3b0',
  SCRIPT_URL: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
  MAX_ATTEMPTS: 3,
  RETRY_DELAY: 1500,
};

function CookieBannerScripts() {
  const [languageInLocalStorage, setLanguageInLocalStorage] = useState(
    localStorage.getItem('lng')
  );

  useEffect(() => {
    const changeLangHandler = (event) => {
      if (event.key === 'lng') {
        setLanguageInLocalStorage(event.value);
      }
    };
    document.addEventListener('changeLang', changeLangHandler);

    return () => {
      document.removeEventListener('changeLang', changeLangHandler);
    };
  }, []);

  const languageCode = useMemo(() => {
    if (languageInLocalStorage) {
      return languageInLocalStorage;
    }

    const paths = window.location.pathname;
    const languageOnPathName = paths.split('/').filter((path) => path)[0];

    // "registration router" has a different format to get the culture
    if (languageOnPathName === 'registration') {
      return getCultureFromRegistrationLink(paths);
    }
    // normal routers
    return checkIsLanguageSupported(languageOnPathName)
      ? languageOnPathName
      : 'en';
  }, [languageInLocalStorage]);

  const oneTrustLanguage = useMemo(() => {
    if (languageCode === 'de-FT') {
      return 'de-DE';
    }

    return languageCode;
  }, [languageCode]);

  useEffect(() => {
    const OptanonWrapper = () => {
      const paths = window.location.pathname;

      if (!paths.includes('data-protection-policy')) {
        return;
      }

      let attempts = 0;

      const checkCookieButton = () => {
        const cookieButton = document.getElementById('ot-sdk-btn');

        if (cookieButton && cookieButton.textContent) {
          return;
        }

        attempts += 1;

        if (attempts < ONETRUST_CONFIG.MAX_ATTEMPTS) {
          setTimeout(checkCookieButton, ONETRUST_CONFIG.RETRY_DELAY);
        }
      };

      checkCookieButton();
    };

    const existingScript = document.getElementById(ONETRUST_CONFIG.SCRIPT_ID);
    if (existingScript) {
      existingScript.remove();
    }

    const script = document.createElement('script');
    script.src = ONETRUST_CONFIG.SCRIPT_URL;
    script.type = 'text/javascript';
    script.async = true;
    script.setAttribute('id', ONETRUST_CONFIG.SCRIPT_ID);
    script.setAttribute('charset', 'UTF-8');
    script.setAttribute('data-language', oneTrustLanguage);
    script.setAttribute('data-domain-script', ONETRUST_CONFIG.DOMAIN_ID);
    script.onload = OptanonWrapper;
    document.body.appendChild(script);

    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, [oneTrustLanguage]);

  return null;
}

function AdobeAnalyticScripts() {
  // eslint-disable-next-line no-underscore-dangle
  useEffect(() => window?._satellite && window?._satellite?.pageBottom(), []);

  return null;
}

function ExternalFileScripts() {
  return (
    <>
      <CookieBannerScripts />
      <AdobeAnalyticScripts />
    </>
  );
}

export default ExternalFileScripts;
