import B2becTable from 'components/B2becTable';
import useDeviceDetect from 'hooks/useDeviceDetect';
import useDummyItems from 'hooks/useDummyItems';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addProductToCart } from 'store/slices/cartSlice';

import usePermission from '../../../../../hooks/usePermission';
import { MODULE_SHOP_NAVIGATION_RIGHTS } from '../../../../../libs/constants/modulerights';
import tableColumns from './TableColumns';

const ProductCardTable = (props) => {
  const { dataSrc, isLoading, onCompareHandler, comparingProductIds } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { isIpadLandscape } = useDeviceDetect();
  const {
    verifiedPermissions: [
      hasAddProductToCartPermission,
      hasProductComparisonPermission,
    ],
  } = usePermission([
    MODULE_SHOP_NAVIGATION_RIGHTS.ADD_PRODUCT_TO_SHOPPING_CART,
    MODULE_SHOP_NAVIGATION_RIGHTS.PRODUCT_COMPARISION,
  ]);

  const shapeOfProduct = {
    pictureUrl: '',
    materialNumber: '',
    materialName: '',
    categoryName: '',
    listPrice: 0,
  };

  const fakeItems = useDummyItems(6, shapeOfProduct);
  const handleCellClick = (materialNumber) => {
    return history.push(linkGenerator(`/product/${materialNumber}`));
  };
  const addToCartHandler = (materialNumber) => {
    dispatch(addProductToCart({ materialNumber }));
  };

  const onCheckHandler = useCallback(
    (product, event) => {
      const isChecked = event?.target?.checked;

      onCompareHandler(isChecked, product);
    },
    [onCompareHandler]
  );

  const renderColumns = () => {
    const currentTableColumns = tableColumns(
      addToCartHandler,
      handleCellClick,
      isLoading,
      onCheckHandler,
      comparingProductIds,
      hasAddProductToCartPermission,
      hasProductComparisonPermission
    );
    // Don't render the column of comaprison feature in mobile view
    return isIpadLandscape
      ? currentTableColumns.filter(
          (column) => column?.dataIndex !== 'comparison'
        )
      : currentTableColumns;
  };

  return (
    <>
      <B2becTable
        style={{ marginTop: 20 }}
        className="order-details-cart-table"
        columns={renderColumns()}
        dataSource={isLoading ? fakeItems : dataSrc}
        pagination={false}
        rowKey="materialNumber"
      />
    </>
  );
};

ProductCardTable.propTypes = {
  dataSrc: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool,
  comparingProductIds: PropTypes.arrayOf(PropTypes.string),
  onCompareHandler: PropTypes.func,
};

ProductCardTable.defaultProps = {
  dataSrc: [],
  isLoading: false,
  comparingProductIds: [],
  onCompareHandler: () => {},
};

export default ProductCardTable;
