import { PunchoutProvider } from 'contexts/punchout-context';
import withUserAndDateFormat from 'HOCs/withUserAndDateFormat';
import { linkGenerator } from 'libs/utils/language';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import DashboardRouteComponent from 'routes/DashboardRouteComponent';
import withCheckingUserStatus from 'routes/withCheckingUserStatus';
import {
  selectIsLoggedIn,
  selectUserId,
} from 'store/selectors/authenticationSelectors';
import { selectShouldRenderServiceMenu } from 'store/selectors/configSelector';

import usePermission from '../hooks/usePermission';

const regex = new RegExp(/request-service|service-packages/);

const ProtectedRoute = ({
  component: Component,
  title = '',
  breadcrumbs,
  pageID = '1',
  path = '',
  exact = false,
  requiredPermission = '',
}) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const userId = useSelector(selectUserId);
  const loginPath = linkGenerator('/');
  const { hasPermission } = usePermission(requiredPermission);
  const shouldRenderServiceMenu = useSelector(selectShouldRenderServiceMenu);

  const renderCallback = (props) => {
    const currentPath = props.location.pathname;
    if (!isLoggedIn && !userId) {
      return redirectToLoginFrom(currentPath);
    }

    if (!!requiredPermission && !hasPermission) {
      return redirectToUnauthorizedPage();
    }

    return component(props);
  };

  const redirectToLoginFrom = (currentPath) => {
    return (
      <Redirect
        to={{
          pathname: loginPath,
          state: { from: currentPath },
        }}
      />
    );
  };

  const redirectToUnauthorizedPage = () => {
    return <Redirect to={{ pathname: '/403' }} />;
  };

  if (!shouldRenderServiceMenu && regex.test(path)) {
    return <Redirect to={{ pathname: '/404' }} />;
  }

  const component = (props) => {
    return (
      <PunchoutProvider>
        <DashboardRouteComponent>
          <Component
            title={title}
            breadcrumbs={breadcrumbs}
            pageId={pageID}
            {...props}
          />
        </DashboardRouteComponent>
      </PunchoutProvider>
    );
  };

  return <Route path={path} exact={exact} render={renderCallback} />;
};

export default withCheckingUserStatus(withUserAndDateFormat(ProtectedRoute));
