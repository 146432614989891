import './styles.scss';

import FormatPrice from 'components/FormatPrice';
import ImageWithFallBack from 'components/ImageWithFallback';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { selectHideListPriceGlobal } from 'store/selectors/configSelector';

export const NOT_FOUND_PRODUCT = 'NOT_FOUND';

export const B2becPromotionItemSkeleton = () => {
  return (
    <div className="b2bec-promotion-item b2bec-promotion-item--skeleton">
      <div className="b2bec-promotion-item__picture">
        <Skeleton style={{ height: 230 }} />
      </div>
      <div className="b2bec-promotion-item__information">
        <span className="b2bec-promotion-item__name">
          <Skeleton />
        </span>
        <span className="b2bec-promotion-item__serial">
          <Skeleton />
        </span>
      </div>
      <div className="b2bec-promotion-item__price">
        <span>
          <Trans i18nKey="productCard.listPrice" />
        </span>
        <span>
          <Skeleton style={{ width: 70 }} />
        </span>
      </div>
    </div>
  );
};

const B2becPromotionItem = (props) => {
  const {
    pictureUrl,
    materialName,
    materialNumber,
    listPrice,
    currencyUnit,
    displayListPrice,
  } = props;

  const shouldHideListPriceGlobally = useSelector(selectHideListPriceGlobal);

  let materialNameToShow = 'N/A';
  if (materialName && materialName !== NOT_FOUND_PRODUCT) {
    materialNameToShow = materialName;
  }

  return (
    <div className="b2bec-promotion-item">
      <div className="b2bec-promotion-item__picture">
        <ImageWithFallBack height={234} src={pictureUrl} preview={false} />
      </div>
      <div className="b2bec-promotion-item__information">
        <span className="b2bec-promotion-item__name">{materialNameToShow}</span>
        <span className="b2bec-promotion-item__serial">
          {materialNumber || 'N/A'}
        </span>
      </div>
      {shouldHideListPriceGlobally ? null : (
        <div className="b2bec-promotion-item__price">
          <span>
            <Trans i18nKey="productCard.listPrice" />
          </span>
          <span>
            {!!displayListPrice && listPrice ? (
              <FormatPrice price={listPrice} currencyUnit={currencyUnit} />
            ) : (
              <Trans i18nKey="productCard.onRequest" />
            )}
          </span>
        </div>
      )}
    </div>
  );
};

B2becPromotionItem.defaultProps = {
  materialName: '',
  pictureUrl: '',
  materialNumber: '',
  listPrice: undefined,
  currencyUnit: '',
  displayListPrice: true,
};

B2becPromotionItem.propTypes = {
  materialName: PropTypes.string,
  pictureUrl: PropTypes.string,
  materialNumber: PropTypes.string,
  listPrice: PropTypes.number,
  currencyUnit: PropTypes.string,
  displayListPrice: PropTypes.bool,
};

export default B2becPromotionItem;
