import { Col, Row } from 'antd';
import { isValidArray } from 'libs/utils/array';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const getUserName = (username) => {
  return isValidArray(username) ? username[1] : username;
};

const InformationBlock = ({ partnerNumber, username, email }) => {
  const { t } = useTranslation();
  return (
    <div className="profile__head">
      <div className="profile__head__background" />
      <div className="profile__head__wrapper">
        <h1 className="profile__title">{t('myProfile.title')}</h1>
        <Row gutter={[12, 24]}>
          <Col xs={8} sm={7} xl={8}>
            <p className="profile__head__title mb-0">
              {t('myProfile.header.title')}
            </p>
            <p className="profile__head__value">{partnerNumber || 'N/A'}</p>
          </Col>
          <Col xs={8} sm={7} xl={8}>
            <p className="profile__head__title mb-0">
              {t('myProfile.header.userName')}
            </p>
            <p className="profile__head__value">{getUserName(username)}</p>
          </Col>
          <Col xs={8} sm={7} xl={8}>
            <p className="profile__head__title mb-0">
              {t('myProfile.header.emailAddress')}
            </p>
            <p className="profile__head__value">{getUserName(email)}</p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

InformationBlock.propTypes = {
  partnerNumber: PropTypes.string,
  username: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  email: PropTypes.string,
};
InformationBlock.defaultProps = {
  partnerNumber: '',
  username: '',
  email: '',
};
export default InformationBlock;
