import { Col, Row } from 'antd';
import clsx from 'clsx';
import FormatPrice from 'components/FormatPrice';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import styles from './B2BecCartSummary.module.scss';

const columnSpans = {
  left: { xs: 14, sm: 14, md: 14, lg: 14, xl: 14 },
  right: { xs: 10, sm: 10, md: 10, lg: 10, xl: 10 },
};

const RenderSubtitles = ({
  title,
  price,
  currencyUnit,
  bold,
  uppercase,
  isLoading,
}) => {
  return (
    <Row className={clsx(bold && styles.bold, uppercase && styles.uppercase)}>
      <Col className={styles.subtitle} {...columnSpans.left}>
        <p className="mb-0">{title}</p>
      </Col>
      <Col className={styles.subtitle__value} {...columnSpans.right}>
        {isLoading ? (
          <Skeleton />
        ) : (
          <FormatPrice
            price={price}
            currencyUnit={currencyUnit}
            wrappedBy="p"
            className="mb-0"
          />
        )}
      </Col>
    </Row>
  );
};

const B2BecCartSummary = (props) => {
  const { t } = useTranslation();

  const {
    cartSubTotal,
    cartTotal,
    shippingCosts,
    voucherValue,
    additionalCost,
    vat,
    cartTotalVAT,
    currencyUnit,
    checkoutSettings,
    isLoading,
  } = props;

  return (
    <div className={styles.B2BecCartSummary}>
      <div>
        <h3 className="cost-summary__title mb-0">
          {t('checkout.step3.upperSection.rightPart.title')}
        </h3>
        <RenderSubtitles
          title={t('checkout.step3.upperSection.rightPart.subtotal')}
          currencyUnit={currencyUnit}
          price={cartSubTotal}
          isLoading={isLoading}
        />
        <RenderSubtitles
          title={t('checkout.step3.upperSection.rightPart.additionalCost')}
          currencyUnit={currencyUnit}
          price={additionalCost}
          isLoading={isLoading}
        />
        <RenderSubtitles
          title={t('checkout.step3.upperSection.rightPart.shippingCosts')}
          currencyUnit={currencyUnit}
          price={shippingCosts}
          isLoading={isLoading}
        />
        <RenderSubtitles
          title={t('checkout.step3.upperSection.rightPart.total')}
          currencyUnit={currencyUnit}
          price={cartTotal}
          isLoading={isLoading}
          bold
        />
        {voucherValue?.code && (
          <Row>
            <Col className={styles.subtitle} {...columnSpans.left}>
              <p className="mb-0">{t('voucher.fields.voucherCode')}</p>
              <p className="mb-0">{voucherValue.code}</p>
            </Col>
            <Col className={styles.subtitle__value} {...columnSpans.right}>
              {isLoading ? (
                <Skeleton />
              ) : (
                <FormatPrice
                  price={voucherValue?.totalDiscount}
                  currencyUnit={currencyUnit}
                  wrappedBy="p"
                  className="mb-0"
                />
              )}
            </Col>
          </Row>
        )}

        <RenderSubtitles
          title={t('vat')}
          currencyUnit={currencyUnit}
          uppercase
          price={vat}
          hidden={!checkoutSettings?.includingVAT}
          isLoading={isLoading}
        />
        <RenderSubtitles
          title={t('totalWithVat')}
          currencyUnit={currencyUnit}
          price={cartTotalVAT}
          hidden={!checkoutSettings?.includingVAT}
          isLoading={isLoading}
          bold
        />
      </div>
    </div>
  );
};

export default B2BecCartSummary;
