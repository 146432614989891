import './styles.scss';

import { Col, Row, Skeleton as AntDSkeleton } from 'antd';
import clsx from 'clsx';
import B2BecImageWithFallback from 'components/B2BecImageWithFallback';
import B2BecLink from 'components/B2BLink';
import CustomButton from 'components/CustomButton';
import { PlusIcon } from 'components/Icons';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { PAGE_TYPES, TRACK_DATA } from 'libs/constants/adobeAnalytics';
import { isValidArray } from 'libs/utils/array';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  selectIsLoadingSubCategoryOverview,
  selectSubCategoryOverviewData,
} from 'store/selectors/categorySelector';
import { trackCategory } from 'store/slices/adobeAnalyticSlice';
import {
  getProductSubCategoryOverviewData,
  resetProductSubCategoryOverviewData,
} from 'store/slices/categorySlice';

import SubItemList, { SUB_ITEM_LIST_TYPES } from '../components/SubItemList';
import useCalculateScrollbarWidth from 'hooks/useCalculateScrollbarWidth';

const chunkInto2Array = (arr, length, minSize = 5) => {
  const maxSize = Math.ceil(arr.length / length);
  const size = Math.max(maxSize, minSize);

  if (!Array.isArray(arr) || arr.length === 0) {
    return [];
  }

  return Array.from({ length }, (_, i) => arr.slice(i * size, i * size + size));
};

function SubCategoryOverviewPage(props) {
  const { t } = useTranslation();
  const { categoryId } = useParams();
  const location = useLocation();
  const productTypeLinkPrefix = useMemo(() => {
    const result = location.pathname.match(/^\/accessories|\/detergents/);
    return result && result.length > 0 ? result[0] : '';
  }, [location.pathname]);

  const [shouldRenderImageWrapper, setShouldRenderImageWrapper] =
    useState(true);
  const { title: pageTitle, breadcrumbs, pageId } = props;
  const { setTitle, setIsTitleTranslated } = useDocumentTitle(pageTitle);
  const breadcrumbsData = () => {
    switch (productTypeLinkPrefix) {
      case '/accessories':
        return [
          {
            titleData: [t('breadcrumbTitle.accessories')],
            pathData: ['accessories'],
          },
        ];
      case '/detergents':
        return [
          {
            titleData: [t('breadcrumbTitle.detergents')],
            pathData: ['detergents'],
          },
        ];
      default:
        return [];
    }
  };
  const [setBreadcrumbsData] = useBreadcrumbs(breadcrumbs, breadcrumbsData);
  const { setPageInfoData } = useAdobeAnalysis(TRACK_DATA.CATEGORIES);

  const history = useHistory();
  const dispatch = useDispatch();

  const isLoadingSubCategoryOverview = useSelector(
    selectIsLoadingSubCategoryOverview
  );
  const subCategoryOverviewData = useSelector(selectSubCategoryOverviewData);

  const {
    categoryName: title = '',
    text: subtitle = '',
    subCategories = [],
  } = subCategoryOverviewData;

  useCalculateScrollbarWidth();

  useEffect(
    () => () => {
      dispatch(resetProductSubCategoryOverviewData());
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(getProductSubCategoryOverviewData({ categoryId }));
  }, [dispatch, categoryId]);

  useEffect(() => {
    if (subCategories.length === 0) {
      history.replace(`${location.pathname}/subcategory/${categoryId}`);
    }
  }, [history, subCategories, location.pathname, categoryId]);

  useEffect(() => {
    if (title) {
      setPageInfoData({
        pageName: `sub categories overview:${title}`,
        pageType: PAGE_TYPES.SUB_CATEGORIES,
        pageId,
      });
    }
  }, [setPageInfoData, title, pageId]);

  useEffect(() => {
    if (title) {
      switch (productTypeLinkPrefix) {
        case '/accessories':
          setBreadcrumbsData([
            {
              titleData: [t('breadcrumbTitle.accessories')],
              pathData: ['accessories'],
            },
            {
              titleData: [title],
              pathData: ['accessories', categoryId],
            },
          ]);
          break;
        case '/detergents':
          setBreadcrumbsData([
            {
              titleData: [t('breadcrumbTitle.detergents')],
              pathData: ['detergents'],
            },
            {
              titleData: [title],
              pathData: ['detergents', categoryId],
            },
          ]);
          break;
        default:
          setBreadcrumbsData([
            {
              titleData: [title],
              pathData: [categoryId],
            },
          ]);
      }
      setIsTitleTranslated(true);
      setTitle(title);

      dispatch(trackCategory({ primaryCategory: title }));
    }
  }, [
    t,
    setTitle,
    setIsTitleTranslated,
    productTypeLinkPrefix,
    setBreadcrumbsData,
    title,
    categoryId,
    dispatch,
  ]);

  const renderButton = useCallback(
    (subCategoryId, options = { isEmptyList: false }) => (
      <div
        className={clsx('sub-item__list-button', {
          'mt-0': options.isEmptyList,
        })}
      >
        <CustomButton
          buttonSize="small"
          type="ghost"
          icon={<PlusIcon />}
          onClick={() =>
            history.push(`${location.pathname}/subcategory/${subCategoryId}`)
          }
        >
          {t('productSubCategoryOverview.button.seeMore')}
        </CustomButton>
      </div>
    ),
    [history, location.pathname, t]
  );

  const renderClassTypeList = (subCategory) => {
    const { categoryId: subCategoryId, subCategories: classTypeList = [] } =
      subCategory;

    const listDatas = chunkInto2Array(classTypeList, 2);

    if (listDatas.length === 0) {
      return (
        <Col md={12} lg={12} xl={12}>
          {renderButton(subCategoryId, { isEmptyList: true })}
        </Col>
      );
    }

    return listDatas.map((data, index) => {
      const key = `sub-item-${subCategoryId}-${index}`;
      const shouldShowSeeMore = index === listDatas.length - 1;
      const isEmptyList = data.length === 0;

      return (
        <Col key={key} md={12} lg={12} xl={12}>
          <div className="sub-item__list-wrapper">
            <SubItemList
              type={SUB_ITEM_LIST_TYPES.SUB_CATEGORY_OVERVIEW}
              mainCategoryId={categoryId}
              subCategoryId={subCategoryId}
              listData={data}
            />
            {shouldShowSeeMore
              ? renderButton(subCategoryId, { isEmptyList })
              : null}
          </div>
        </Col>
      );
    });
  };

  const handleSubcategoryImageLoadFailed = (hasLoadedFailed) => {
    if (hasLoadedFailed) {
      setShouldRenderImageWrapper(false);
    } else {
      setShouldRenderImageWrapper(true);
    }
  };

  const renderSubCategoryList = () => {
    return isValidArray(subCategories)
      ? subCategories.map((subCategory) => {
          const imgSrc = isValidArray(subCategory.images)
            ? subCategory.images[0]?.url
            : '';
          const shouldShowImage = shouldRenderImageWrapper && !!imgSrc;
          return (
            <div
              className="sub-category-overview__sub-category-item__wrapper"
              key={subCategory.categoryName}
            >
              <B2BecLink
                to={`${location.pathname}/subcategory/${subCategory.categoryId}`}
                className="fadeIn-05"
              >
                <h2>{subCategory.categoryName}</h2>
              </B2BecLink>
              <Row gutter={[32, 0]}>
                {shouldShowImage ? (
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={10}>
                    <B2BecLink
                      to={`${location.pathname}/subcategory/${subCategory.categoryId}`}
                      className="fadeIn-2"
                    >
                      <B2BecImageWithFallback
                        src={imgSrc}
                        alt={subCategory.categoryName}
                        isHiddenOnError
                        onSubcategoryImageLoadFailed={
                          handleSubcategoryImageLoadFailed
                        }
                      />
                    </B2BecLink>
                  </Col>
                ) : null}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <p className="sub-category-overview__sub-category-item__description fadeIn-07">
                    {subCategory.text}
                  </p>
                  <Row gutter={24}>{renderClassTypeList(subCategory)}</Row>
                </Col>
              </Row>
            </div>
          );
        })
      : null;
  };

  const renderSubCategoryListSkeleton = () => {
    return (
      <div className="sub-category-overview__sub-category-item__wrapper">
        <Row gutter={[32, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <h2>
              <Skeleton />
            </h2>
          </Col>
        </Row>
        <Row gutter={[32, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Skeleton width="100%" height={200} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <p className="sub-category-overview__sub-category-item__description">
              <Skeleton count={3} />
            </p>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <>
      <div className="sub-category-overview__wrapper">
        <div className="full-width-page-component__wrapper">
          <div className="sub-category-overview__heading">
            {isLoadingSubCategoryOverview ? (
              <div className="sub-category-overview__heading__inner">
                <AntDSkeleton active />
              </div>
            ) : (
              <div className="sub-category-overview__heading__inner fadeIn-06">
                <h1>{title}</h1>
                <p className="sub-category-overview__heading__subtitle">
                  {subtitle}
                </p>
              </div>
            )}
          </div>
        </div>
        {isLoadingSubCategoryOverview
          ? renderSubCategoryListSkeleton()
          : renderSubCategoryList()}
      </div>
    </>
  );
}

SubCategoryOverviewPage.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default SubCategoryOverviewPage;
