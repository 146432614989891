import './styles.scss';

import { LoadingOutlined } from '@ant-design/icons';
import { Avatar, Col, Row, Tooltip } from 'antd';
import { ReactComponent as InformationIcon } from 'assets/icons/information.svg';
import AddProductIcon from 'assets/img/addproduct.svg';
import SampleFile from 'assets/items.csv';
import B2becTranslation from 'components/B2becTranslation';
import B2becUpload from 'components/B2becUpload';
import CustomButton from 'components/CustomButton';
import { ChevronLeftIcon } from 'components/Icons';
import { NotificationManager } from 'components/Notification';
import PermissionWrapper from 'HOCs/permissionWrapper';
import useAdobeAnalysis from 'hooks/useAdobeAnalysis';
import useAsync from 'hooks/useAsync';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import useDeviceDetect from 'hooks/useDeviceDetect';
import useDocumentTitle from 'hooks/useDocumentTitle';
import usePunchout from 'hooks/usePunchout';
import { ACCEPTED_UPLOAD_FILE_EXTENSIONS, ASYNC_STATUS } from 'libs/constants';
import {
  LINK_NAMES,
  PAGE_TYPES,
  TRACK_DATA,
} from 'libs/constants/adobeAnalytics';
import {
  MODULE_CART_CHECKOUT_RIGHTS,
  MODULE_SHOP_NAVIGATION_RIGHTS,
} from 'libs/constants/modulerights';
import { analyzeLinkName } from 'libs/utils/adobeAnalysis';
import { isEmptyArray, isValidArray } from 'libs/utils/array';
import { linkGenerator } from 'libs/utils/language';
import { downloadPDF } from 'libs/utils/PDFHandler';
import { isStringNotEmpty } from 'libs/utils/stringUltis';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { cartService, reportService } from 'services';
import {
  selectCartItems,
  selectIsAvoidProceedingCart,
  selectIsEmptyingCart,
  selectIsFetchingCart,
} from 'store/selectors/cartSelector';
import {
  selectDeactivateShoppingCart,
  selectHideQuickEntry,
} from 'store/selectors/configSelector';
import { selectHasSessionBasedShoppingCart } from 'store/selectors/userSelector';
import { trackLinkName } from 'store/slices/adobeAnalyticSlice';
import {
  addEmptyRow,
  emptyCart,
  getCartItems,
  removeProductAddedFromQuickEntry,
} from 'store/slices/cartSlice';

import AddQuickEntry from './AddQuickEntry';
import CartItemTable from './CartItemTable';
import EmptyCartConfirmModal from './EmptyCartConfirmModal';
import CartMobile from './Mobile';
import RedeemVoucher from './RedeemVoucher';

const handleExportCartError = () =>
  NotificationManager.error({
    message: 'notification.error.summary.cart',
  });

const handleExportCartSuccess = (response) => {
  if (response?.status === 200 && response?.data) {
    if (
      isStringNotEmpty(response.data.file) &&
      isStringNotEmpty(response.data.fileName)
    ) {
      downloadPDF(response.data);
    } else {
      handleExportCartError();
    }
  } else {
    handleExportCartError();
  }
};

const Cart = ({ title, breadcrumbs, pageId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isEmptyCartModalVisible, setIsEmptyCartModalVisible] = useState(false);
  const { sessionId, onSubmitPunchoutData } = usePunchout() || {};
  const [isUploading, setIsUploading] = useState(false);
  const isShoppingCartDeactivated = useSelector(selectDeactivateShoppingCart);

  useAdobeAnalysis(TRACK_DATA.CART, {
    pageName: 'cart',
    pageId,
    pageType: PAGE_TYPES.CART,
  });

  useDocumentTitle(title);
  useBreadcrumbs(breadcrumbs);

  const { isMobile } = useDeviceDetect();
  const [isAddBtnDisabled, setIsDisableAddBtnDisabled] = useState(false);
  const quickEntryRef = useRef(null);

  const isLoading = useSelector(selectIsFetchingCart);
  const cartItems = useSelector(selectCartItems);
  const isAvoidProceedingCart = useSelector(selectIsAvoidProceedingCart);
  const isEmptyingCart = useSelector(selectIsEmptyingCart);
  const hasSessionBasedShoppingCart = useSelector(
    selectHasSessionBasedShoppingCart
  );
  const hideQuickEntryConfig = useSelector(selectHideQuickEntry);
  const isShowQuickEntry = !hideQuickEntryConfig && !isLoading;

  const { execute: executeExportCart, status: exportCartStatus } = useAsync(
    (ssId) => reportService.exportShoppingCart({ sessionId: ssId }),
    false,
    handleExportCartSuccess,
    handleExportCartError
  );

  const onHandleExportCart = useCallback(() => {
    dispatch(trackLinkName(analyzeLinkName(LINK_NAMES.PDF_EXPORT)));

    executeExportCart(sessionId);
  }, [executeExportCart, sessionId, dispatch]);

  const showAddQuickEntry = () => {
    const searchRow = {
      id: '',
      materialNumber: <AddQuickEntry />,
      imgUrl: (
        <Avatar
          size={{ xs: 64, md: 80 }}
          shape="square"
          src={AddProductIcon}
          className="cart__image"
        />
      ),
      price: 0,
      quantity: 0,
      emptyRow: true,
    };

    dispatch(addEmptyRow(searchRow));
  };

  const scrollToRef = (ref) => {
    window.scrollTo(0, ref.current.offsetTop - 620);
  };

  const proceedToCheckoutHandler = useCallback(() => {
    try {
      if (isEmptyArray(cartItems)) {
        NotificationManager.error({
          message: 'notification.error.proceedCheckout',
          description: 'notification.error.cartListIsEmpty',
        });
        return;
      }
      // check cart has invalid items
      if ((cartItems || []).some((item) => !!item?.error)) {
        NotificationManager.error({
          message: 'notification.error.proceedCheckout',
          description: 'notification.error.nonexistentNumberInCart',
        });
        return;
      }

      if (
        hasSessionBasedShoppingCart &&
        typeof onSubmitPunchoutData === 'function'
      ) {
        onSubmitPunchoutData();
      } else {
        history.push(linkGenerator('/checkout'));
      }
    } catch (error) {
      console.log('error.message', error.message);
    }
  }, [history, cartItems, hasSessionBasedShoppingCart, onSubmitPunchoutData]);

  const handleEmptyCart = () => {
    dispatch(emptyCart());
    setIsEmptyCartModalVisible(false);
  };

  const handleUploadProductsToCart = async ({ file }) => {
    const isCSV = ACCEPTED_UPLOAD_FILE_EXTENSIONS?.includes(file?.type);

    if (!isCSV) {
      NotificationManager.error({
        message: 'notification.upload.csvOnly',
      });

      return;
    }

    setIsUploading(true);

    const formData = new FormData();
    formData.append('FormFile', file);

    try {
      const uploadResponse = await cartService.uploadProductsToCart({
        formData,
        sessionId,
      });

      if (uploadResponse?.status === 200) {
        dispatch(getCartItems());
        NotificationManager.success({
          message: 'notification.success.upload',
        });
      }
    } catch (error) {
      console.error('Upload error:', error?.message);
      NotificationManager.error({
        message: 'notification.error.upload',
      });
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    dispatch(getCartItems());
  }, [dispatch]);

  useEffect(() => {
    if (
      isValidArray(cartItems) &&
      cartItems.some((item) => item.emptyRow === true)
    ) {
      setIsDisableAddBtnDisabled(true);

      if (!isMobile) {
        scrollToRef(quickEntryRef);
      }
    } else {
      setIsDisableAddBtnDisabled(false);
    }
  }, [cartItems, isMobile]);

  useEffect(
    () => () => {
      dispatch(removeProductAddedFromQuickEntry());
    },
    [dispatch]
  );

  return !isMobile ? (
    <div className="cart-wrapper">
      <div className="cart-heading">
        <h1>{t('cart.header')}</h1>
        <div className="cart-heading__buttons mb-4">
          <div className="leftSection">
            {isShowQuickEntry && (
              <PermissionWrapper
                permission={
                  MODULE_SHOP_NAVIGATION_RIGHTS.ADD_PRODUCT_WITH_QUICK_ENTRY
                }
              >
                <CustomButton
                  onClick={showAddQuickEntry}
                  disabled={isAddBtnDisabled || isAvoidProceedingCart}
                  type="ghost"
                  className="cart-heading__add mr-4"
                >
                  {t('cart.addQuickEntryBtn')}
                </CustomButton>
              </PermissionWrapper>
            )}
            <PermissionWrapper
              permission={MODULE_SHOP_NAVIGATION_RIGHTS.EMPTY_CART}
            >
              <CustomButton
                onClick={() => setIsEmptyCartModalVisible(true)}
                disabled={isEmptyingCart || isAddBtnDisabled}
                type="ghost"
              >
                {t('cart.modal.emptyCartBtn')}
              </CustomButton>
            </PermissionWrapper>
          </div>
          <div className="rightSection">
            <div>
              <Tooltip
                title={
                  <B2becTranslation
                    value="cart.uploadCart"
                    isComponent
                    htmlTags={[
                      <a
                        aria-label="Download sample file"
                        href={SampleFile}
                        download="sample_file.csv"
                        key="upload_sample"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        csv files
                      </a>,
                    ]}
                  />
                }
              >
                <InformationIcon width={24} height={24} />
              </Tooltip>
            </div>
            <B2becUpload
              isUploading={isUploading}
              customRequest={handleUploadProductsToCart}
              text={t('cart.buttons.uploadCsvFile')}
            />
          </div>
        </div>
      </div>

      <CartItemTable isLoading={isLoading} />

      {isEmptyCartModalVisible && (
        <EmptyCartConfirmModal
          isEmptyCartModalVisible={isEmptyCartModalVisible}
          onEmptyCart={handleEmptyCart}
          onCancel={() => setIsEmptyCartModalVisible(false)}
        />
      )}

      <div className="cart-bottom" ref={quickEntryRef}>
        <div className="cart-bottom__action">
          <div className="cart-bottom__action__link" style={{ marginTop: 10 }}>
            <CustomButton
              type="ghost"
              className="cart-bottom__action__link--with-icon"
              onClick={() => history.push(linkGenerator('/my-workspace'))}
            >
              <ChevronLeftIcon />
              {t('cart.continueShopping')}
            </CustomButton>
          </div>
          {isLoading || isShoppingCartDeactivated ? null : (
            <div style={{ marginTop: 10 }}>
              <CustomButton
                onClick={proceedToCheckoutHandler}
                disabled={isAvoidProceedingCart}
              >
                {hasSessionBasedShoppingCart
                  ? t('cart.proceedToPurchaseSystem')
                  : t('cart.proceedToCheckout')}
              </CustomButton>
            </div>
          )}
        </div>
        <PermissionWrapper
          permission={MODULE_SHOP_NAVIGATION_RIGHTS.CART_EXPORT}
        >
          <Row gutter={8} style={{ marginTop: '48px' }}>
            <Col>
              <CustomButton
                type="ghost"
                buttonSize="small"
                icon={
                  exportCartStatus === ASYNC_STATUS.PENDING && (
                    <LoadingOutlined />
                  )
                }
                disabled={
                  exportCartStatus === ASYNC_STATUS.PENDING ||
                  isAvoidProceedingCart
                }
                onClick={onHandleExportCart}
              >
                {t('cart.buttons.exportPDF')}
              </CustomButton>
            </Col>
          </Row>
        </PermissionWrapper>

        <PermissionWrapper
          permission={MODULE_CART_CHECKOUT_RIGHTS.ADD_VOUCHER_TO_CART}
        >
          <RedeemVoucher isDisabled={isAvoidProceedingCart} />
        </PermissionWrapper>
      </div>
    </div>
  ) : (
    <CartMobile
      showAddQuickEntry={showAddQuickEntry}
      isAddBtnDisabled={isAddBtnDisabled}
      isLoading={isLoading}
      proceedToCheckoutHandler={proceedToCheckoutHandler}
      isShowQuickEntry={isShowQuickEntry}
    />
  );
};

Cart.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageId: PropTypes.string.isRequired,
};

export default Cart;
