export const MODULE_DEBUGGING_RIGHTS = {
  READ_DEBUG: 'mkb:read_debug',
};

export const MODULE_USER_MANAGEMENT_RIGHTS = {
  VIEW_USER: 'mkb:read_users',
  MASS_UPLOAD_INVITATION: 'mkb:upload_invitations',
  CREATE_INVITATION: 'mkb:create_invitation',
  DELETE_INVITATION: 'mkb:delete_invitation',
  VIEW_USER_DETAIL: 'mkb:read_user',
  UPDATE_USER: 'mkb:update_user',
  DOWNLOAD_USER: 'mkb:download_user',
  DELETE_USER: 'mkb:delete_user',
  RESET_USER_PASSWORD: 'mkb:update_userpassword',
};

export const MODULE_PROFILE_RIGHTS = {
  VIEW_PROFILE: 'mkb:read_profile',
  RESET_OWN_PASSWORD: 'mkb:update_ownpassword',
  REQUEST_DELIVERY_ADDRESS_CHANGE: 'mkb:update_deliveryaddr',
  REQUEST_INVOICE_ADDRESS_CHANGE: 'mkb:update_invoiceaddr',
  CHANGE_LANGUAGE: 'mkb:update_language',
  SEE_LOGOUT_BUTTON: 'mkb:see_logout_button',
};

export const MODULE_SHOP_NAVIGATION_RIGHTS = {
  USE_SEARCH: 'mkb:search',
  VIEW_PRODUCT_INFORMATION: 'mkb:read_product',
  VIEW_LIST_PRICE: 'mkb:read_listprice',
  VIEW_SERVICE_PACKAGE_INFORMATION: 'mkb:read_servicepackage',
  ADD_PRODUCT_TO_SHOPPING_CART: 'mkb:add_cart',
  ADD_PRODUCT_WITH_QUICK_ENTRY: 'mkb:add_quickentry',
  PRODUCT_COMPARISION: 'mkb:compare_products',
  OPEN_SPARE_PART_SHOP: 'mkb:read_spareparts',
  VIEW_CART: 'mkb:read_cart',
  CART_EXPORT: 'mkb:export_cart',
  EMPTY_CART: 'mkb:empty_cart',
  DELETE_PRODUCT_FROM_CART: 'mkb:delete_cart',
  CHANGE_CART: 'mkb:update_cart',
  VIEW_PROMOTION: 'mkb:read_promotion',
  USE_PRODUCT_NAVIGATION: 'mkb:read_navigation',
};

export const MODULE_CART_CHECKOUT_RIGHTS = {
  VIEW_INDIVIDUAL_PRICE: 'mkb:read_individualprice',
  ADD_VOUCHER_TO_CART: 'mkb:add_voucher',
  REMOVE_VOUCHER_FROM_CART: 'mkb:remove_voucher',
  CHANGE_DELIVERY_ADDRESS: 'mkb:update_cart_deliveryaddr',
};

export const MODULE_PROMOTION_VOUCHER_ADMINISTRATION_RIGHTS = {
  UPDATE_PROMOTION: 'mkb:update_promotion',
  CREATE_VOUCHER: 'mkb:create_voucher',
  UPDATE_VOUCHER: 'mkb:update_voucher',
  DELETE_VOUCHER: 'mkb:delete_voucher',
  VIEW_VOUCHER: 'mkb:read_voucher',
};

export const MODULE_ADMINISTRATION_RIGHTS = {
  VIEW_ADMIN_AREA: 'mkb:read_admin',
  CRUD_CONTACT_REASON: 'mkb:contactreasons',
  UPLOAD_EXCLUDED: 'mkb:upload_excluded',
  VIEW_CONFIGURATION: 'mkb:read_config',
  UPDATE_CONFIGURATION: 'mkb:update_config',
  RESET_CONFIGURATION: 'mkb:reset_config',
  VIEW_PRODUCT_PORTFOLIO_MANAGEMENT: 'mkb:read_portfolio',
  EDIT_PPM: 'mkb:update_portfolio',
  SUPPORT_AREA: 'mkb:supportarea',
  ROCK_LIST: 'mkb:rocklist',
  ROLE_RIGHT: 'mkb:roleright',
};

export const MODULE_ORDER_RIGHTS = {
  VIEW_ORDER_DETAIL: 'mkb:read_order',
  DOWNLOAD_INVOICE: 'mkb:download_invoice',
  VIEW_ORDER_HISTORY: 'mkb:read_history',
  REORDER_HISTORIC_ORDER: 'mkb:reorder_history',
};

export const MODULE_SERVICE_RIGHTS = {
  CREATE_SEND_SERVICE_REQUEST: 'mkb:create_servicerequest',
  CREATE_MAINTENANCE_SERVICE_CASE: 'mkb:create_maintenanceservicecase',
  VIEW_CONTACT_FORM: 'mkb:read_contactform',
  CREATE_REPAIR_SERVICE_CASE: 'mkb:create_repairservicecase',
  CREATE_PRODUCT_REQUEST: 'mkb:create_productrequest',
};

export const MODULE_WISHLIST_RIGHTS = {
  VIEW_WISHLIST: 'mkb:read_wishlist',
  ADD_WISHLIST: 'mkb:create_wishlist',
  EDIT_SHARE_WISHLIST: 'mkb:update_wishlist',
  ADD_PRODUCT_CHANGE_WISHLIST: 'mkb:update_quantity',
  ADD_WISHLIST_TO_CART: 'mkb:add_wishlistcart',
  DELETE_WISHLIST: 'mkb:delete_wishlist',
};

export const MODULE_MACHINE_RIGHTS = {
  VIEW_MACHINE_LIST: 'mkb:read_machinelist',
};

export const MODULE_RENTAL_MANAGEMENT_RIGHTS = {
  VIEW_RENTAL: 'mkb:view_rental',
  ADD_PRODUCT: 'mkb:add_products_rental',
  DELETE_PRODUCT: 'mkb:delete_products_rental',
};
