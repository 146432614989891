import { Layout } from 'antd';
import BackToTopButton from 'components/BackToTopButton';
import Header from 'components/Header';
import Footer from 'components/Layout/components/Footer';
import MainContent from 'components/MainContent';
import SAPLogView from 'components/SAPLog';
import ScrollToTop from 'components/ScrollToTop';
import Sidebar from 'components/Sidebar';
import { SearchProvider } from 'contexts/search';
import PropTypes from 'prop-types';
import React from 'react';

const DashboardRouteComponent = ({ children }) => {
  return (
    <SearchProvider>
      <ScrollToTop />
      <Header />
      <Layout
        style={{
          backgroundColor: 'transparent',
          minHeight: 365,
        }}
      >
        <Sidebar />
        <MainContent>
          {children}
          <SAPLogView />
        </MainContent>
      </Layout>
      <BackToTopButton />
      <Footer />
    </SearchProvider>
  );
};

DashboardRouteComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

DashboardRouteComponent.defaultProps = {
  children: () => <></>,
};

export default DashboardRouteComponent;
