import '../../styles.scss';

import { Checkbox, Form, Input, Modal } from 'antd';
import B2becAddressForm from 'components/B2becAddressForm';
import CustomButton from 'components/CustomButton';
import { ADDRESS_ACTION_TYPES } from 'libs/constants/actionTypes';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import PermissionWrapper from '../../../../HOCs/permissionWrapper';
import { MODULE_PROFILE_RIGHTS } from '../../../../libs/constants/modulerights';

const CheckboxGroup = Checkbox.Group;
const { confirm } = Modal;

const AddressDetailBlock = (props) => {
  const { t } = useTranslation();
  const {
    actionType,
    cancelRequest,
    handleDeleteAddress,
    handleAddAddress,
    handleChangeAddress,
    loading,
  } = props;

  const renderActionButton = () =>
    actionType === ADDRESS_ACTION_TYPES.CHANGE_ADDRESS ? (
      <PermissionWrapper
        permission={MODULE_PROFILE_RIGHTS.REQUEST_DELIVERY_ADDRESS_CHANGE}
      >
        <CustomButton
          className="btn btn--submit mt-6"
          type="primary"
          onClick={handleChangeAddress}
          disabled={loading}
          loading={loading}
        >
          {t('myProfile.button.requestChanges')}
        </CustomButton>
      </PermissionWrapper>
    ) : (
      <CustomButton
        className="btn btn--submit mt-6"
        type="primary"
        onClick={handleAddAddress}
        disabled={loading}
        loading={loading}
      >
        {t('myProfile.button.addAddress')}
      </CustomButton>
    );

  const showDeleteConfirm = () => {
    confirm({
      title: t('myProfile.deleteAddressModal.title'),
      content: t('myProfile.deleteAddressModal.content'),
      okText: t('myProfile.deleteAddressModal.confirmBtn'),
      okType: 'danger',
      cancelText: t('myProfile.deleteAddressModal.cancelBtn'),
      onOk() {
        handleDeleteAddress();
      },
      onCancel() {},
    });
  };

  const renderDeleteButton = () =>
    actionType === ADDRESS_ACTION_TYPES.CHANGE_ADDRESS ? (
      <PermissionWrapper
        permission={MODULE_PROFILE_RIGHTS.REQUEST_DELIVERY_ADDRESS_CHANGE}
      >
        <CustomButton
          className="btn btn--submit mt-6"
          type="ghost"
          buttonSize="small"
          onClick={showDeleteConfirm}
        >
          {t('myProfile.button.deleteThisAddress')}
        </CustomButton>
      </PermissionWrapper>
    ) : null;

  return (
    <div className="mt-6">
      <Form.Item name="partnerNumber" hidden>
        <Input disabled />
      </Form.Item>

      <Form.Item name="taxNumber" hidden>
        <Input disabled />
      </Form.Item>

      <Form.Item name="phoneNumber" hidden>
        <Input disabled />
      </Form.Item>

      <B2becAddressForm />

      <Form.Item
        name="isDefaultAddress"
        label={t('myProfile.form.deliveryAddress.label')}
        style={{ width: 320 }}
        className="custom-form-item"
      >
        <CheckboxGroup>
          <Checkbox
            value="isDefaultAddress"
            disabled={actionType === ADDRESS_ACTION_TYPES.CHANGE_ADDRESS}
          >
            {t('myProfile.form.setAsDefaultAddress')}
          </Checkbox>
          <span className="profile__checkbox-text">
            {t('myProfile.form.thisIsActuallyDefaultAddress')}
          </span>
        </CheckboxGroup>
      </Form.Item>
      {renderDeleteButton()}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <CustomButton
          className="btn btn--submit mt-6"
          type="ghost"
          disabled={loading}
          onClick={cancelRequest}
        >
          {t('myProfile.button.cancel')}
        </CustomButton>
        {renderActionButton()}
      </div>
    </div>
  );
};

AddressDetailBlock.propTypes = {
  cancelRequest: PropTypes.func.isRequired,
  actionType: PropTypes.string.isRequired,
  handleDeleteAddress: PropTypes.func.isRequired,
  handleAddAddress: PropTypes.func.isRequired,
  handleChangeAddress: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

AddressDetailBlock.defaultProps = {
  loading: false,
};

export default AddressDetailBlock;
